@import Url('../css/reset.css');
@import Url('../css/fontawesome-all.css');
@import Url('../css/animate.css');
@import Url('../css/slick.css');
@import Url('../css/slick-theme.css');
@import Url('../css/global.css');
@import Url('../plugins/global/plugins.bundle.css');
@import Url('../scss/rtl.scss');
@import Url('../scss/style.scss');



app-root {
    width: 100%;
    height: 100%;

    app-main-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        app-container-manage {
            display: flex;
            width: 100%;
            height: 100%;

            app-login, app-sign-up, app-forget-password, app-complete-forget-password {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
            }
        }

        app-auth-client-container {
            height: 100%;
            background-color: #f7f7f7;
        }

        /* app-aside-client {
            display: block;
        } */
    }
}

.menu-sub-dropdown.menu.show,
.menu-sub-dropdown.show[data-popper-placement],
.show.menu-dropdown>.menu-sub-dropdown {
    display: flex;
    position: fixed;
    z-index: 9999999;
}

.nav-line-tabs.nav-line-tabs-2x .nav-item {
    margin-bottom: -2px;
    margin-inline-end: 10px;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.header-social.ul-li ul {
    margin-bottom: 0;
    padding-inline-start: 0 !important;
}

.header-top-cta.ul-li ul {
    margin-bottom: 0;
}

.feah-header-section .header-top-content {
    align-items: center;
}

.no-data-found {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: rgba(172, 171, 171, 0.616);
}

.p-toast {
    position: fixed;
    width: 31rem;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 30px !important;
    height: 30px !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 1rem 0 1rem !important;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 30px !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 65px;
    opacity: 0.2;
    display: block;
    align-self: center;
    padding-inline-end: 10px;
    border-inline-end: 3px solid rgba(0, 0, 0, 0.2);
}

.p-toast-message-success {
    color: #fff !important;
    background-color: #8fc991 !important
}

.p-toast-message-content {
    display: flex;
    align-items: center;
}

.p-toast-detail {
    font-size: 14px;
}

.p-toast-summary {
    font-size: 20px;
}

button.p-ripple.p-element.p-toast-icon-close.p-link {
    color: #fff;
    margin-inline-end: 10px;
}

checkicon.p-element.p-icon-wrapper svg {
    /* width: 200px; */
    color: #fff;
    opacity: 1;
    height: 50px;
    width: 50px;
    filter: invert(1);
    padding: 9px;
}

checkicon.p-element.p-icon-wrapper {
    BACKGROUND-COLOR: #fff;
    width: 50px;
    height: 50px;
    align-items: center;
    border-radius: 50%;
    margin-inline-start: 10px;
}

timescircleicon.p-element.p-icon-wrapper {
    width: 50px;
    height: 50px;
    align-items: center;
    border-radius: 50%;
    margin-inline-start: 10px;
}

timescircleicon.p-element.p-icon-wrapper svg {
    /* width: 200px; */
    color: #fff;
    opacity: .5;
    height: 50px;
    width: 50px;
    filter: invert(1);
    padding: 9px;
}

.p-toast-message-error {
    color: #fff !important;
    background-color: #d3726b !important
}

/* Customize paginator container */
.p-paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
}

/* Customize paginator buttons */
.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: #225929;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    margin: 0 2px;
    border-radius: 4px;
}

/* Customize paginator active page button */
.p-paginator .p-paginator-pages .p-paginator-page.p-paginator-current {
    background-color: #225929;
  }
  p-paginator.p-element {
    background-color: #f7f7f7;
}
  /* Customize paginator button on hover */
/* Customize paginator button on hover */
.p-paginator .p-paginator-pages .p-paginator-page:hover {
    background-color: #144a1b;
}

/* Customize paginator input */
.p-paginator .p-paginator-page p-paginator-page-rpp-options {
    margin-left: 10px;
}

/* Customize paginator summary */
.p-paginator .p-paginator-summary {
    margin-left: 10px;
}
.page-title {
    width: 100%;
}
span.p-element.p-dropdown-label.p-inputtext.p-placeholder.ng-star-inserted {
  
    color: #5e5e5e;
}
.p-dropdown-trigger {
    padding-inline-end: 20px;
}
.arck-contact-page-form #pn_id_2 {
    margin-bottom: 25px;
    padding: 20px !important;
  }
  .arck-contact-page-form #pn_id_4 {
    margin-bottom: 25px;
    padding: 20px !important;
}
  .arck-contact-page-form ul#pn_id_2_list {
    background-color: #fff;
  
    padding: 15px;
}
.arck-contact-page-form ul#pn_id_2_list li{
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px dashed #efefef;
}
.arck-contact-page-form ul#pn_id_2_list li:last-child{
 
    border-bottom: none;
}
@media screen and (max-width: 991px) {
    .w-750px {
      width: 100% !important;
  }
}
.bg-body.d-flex.flex-column.flex-center.rounded-4.w-md-600px.p-10 {
    width: 100%;
}
.d-flex.flex-center.flex-column.align-items-stretch.h-lg-100.w-md-400px {
    width: 95%;
}
.mob-side{

        position: absolute;
        height: 100vh;
        z-index: 9999;
    
}
[dir=ltr] #kt_aside_mobile_toggle {
    position: absolute;
    right: 15px;
    top: 20px;
}
[dir=rtl] #kt_aside_mobile_toggle {
    position: absolute;
    left: 15px;
    top: 20px;
}

[dir=rtl] .mob-side .menu.menu-sub.menu-sub-dropdown.menu-column.menu-rounded.menu-gray-800.menu-state-bg.menu-state-color.fw-semibold.py-4.fs-6.w-275px.show {
    right: 20px;
}
[dir=ltr] .mob-side .menu.menu-sub.menu-sub-dropdown.menu-column.menu-rounded.menu-gray-800.menu-state-bg.menu-state-color.fw-semibold.py-4.fs-6.w-275px.show {
    left: 20px;
}