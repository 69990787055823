:root {
	
	/* #ff6b61 in decimal RGB */
  --main-color: #225929;
  --main-color-2: #225929;
  
  /* #1a1a1a in decimal RGB */
  --black-color: rgb(26, 26, 26);
  /* #1a1a1a in decimal RGB */
  --black-color-2: rgb(31, 31, 31);
  
  /* #1f1f1f in decimal RGB */
  --white-color:rgb(255,255,255);
  
  /* Body-color */
  --body-color: rgb(85, 85, 85);
  --body-color-2: #817E7E;
  /* Fonts */
  --heading: 'Titillium Web';
  --body-font: 'Raleway';
  --heading-2: 'Space Grotesk';
  --body-font-2: 'Outfit';  
  --transition: 500ms all ease;
/* Fonts Size's */

--font-10: 10px;
--font-12: 12px;
--font-13: 13px;
--font-14: 14px;
--font-15: 15px;
--font-16: 16px;
--font-18: 18px;
--font-20: 20px;
--font-22: 22px;
--font-24: 24px;
--font-26: 26px;
--font-28: 28px;
--font-30: 30px;
--font-34: 34px;
--font-36: 36px;
--font-40: 40px;
--font-42: 42px;
--font-44: 44px;
--font-48: 48px;
--font-50: 50px;
--font-52: 52px;
--font-54: 54px;
--font-56: 56px;
--font-58: 58px;
--font-60: 60px;
--font-62: 62px;
--font-64: 64px;
--font-66: 66px;
--font-68: 68px;
--font-70: 70px;
--font-72: 72px;
--font-74: 74px;
--font-76: 76px;
--font-78: 78px;
--font-80: 80px;
--font-90: 90px;
--font-100: 100px;
--font-110: 110px;
--font-120: 120px;
--font-150: 150px;
--font-200: 200px;

--margin-zero: 0px;

/* Margin Left Variables */
--margin-left-5: 5px;
--margin-left-10: 10px;
--margin-left-15: 15px;
--margin-left-20: 20px;
--margin-left-25: 25px;
--margin-left-30: 30px;
--margin-left-35: 35px;
--margin-left-40: 40px;
--margin-left-45: 45px;
--margin-left-50: 50px;
--margin-left-55: 55px;
--margin-left-60: 60px;
--margin-left-65: 65px;
--margin-left-70: 70px;
--margin-left-75: 75px;
--margin-left-80: 80px;
--margin-left-85: 85px;
--margin-left-90: 90px;
--margin-left-95: 95px;
--margin-left-100: 100px;

/* Margin Top Variables */
--margin-top-5: 5px;
--margin-top-10: 10px;
--margin-top-15: 15px;
--margin-top-20: 20px;
--margin-top-25: 25px;
--margin-top-30: 30px;
--margin-top-35: 35px;
--margin-top-40: 40px;
--margin-top-45: 45px;
--margin-top-50: 50px;
--margin-top-55: 55px;
--margin-top-60: 60px;
--margin-top-65: 65px;
--margin-top-70: 70px;
--margin-top-75: 75px;
--margin-top-80: 80px;
--margin-top-85: 85px;
--margin-top-90: 90px;
--margin-top-95: 95px;
--margin-top-100: 100px;

/* Margin Right Variables */
--margin-right-5: 5px;
--margin-right-10: 10px;
--margin-right-15: 15px;
--margin-right-20: 20px;
--margin-right-25: 25px;
--margin-right-30: 30px;
--margin-right-35: 35px;
--margin-right-40: 40px;
--margin-right-45: 45px;
--margin-right-50: 50px;
--margin-right-55: 55px;
--margin-right-60: 60px;
--margin-right-65: 65px;
--margin-right-70: 70px;
--margin-right-75: 75px;
--margin-right-80: 80px;
--margin-right-85: 85px;
--margin-right-90: 90px;
--margin-right-95: 95px;
--margin-right-100: 100px;
--margin-right-110: 110px;

/* Margin Bottom Variables */
--margin-bottom-5: 5px;
--margin-bottom-10: 10px;
--margin-bottom-15: 15px;
--margin-bottom-20: 20px;
--margin-bottom-25: 25px;
--margin-bottom-30: 30px;
--margin-bottom-35: 35px;
--margin-bottom-40: 40px;
--margin-bottom-45: 45px;
--margin-bottom-50: 50px;
--margin-bottom-55: 55px;
--margin-bottom-60: 60px;
--margin-bottom-65: 65px;
--margin-bottom-70: 70px;
--margin-bottom-75: 75px;
--margin-bottom-80: 80px;
--margin-bottom-85: 85px;
--margin-bottom-90: 90px;
--margin-bottom-95: 95px;
--margin-bottom-100: 100px;
--margin-bottom-150: 150px;
--margin-bottom-200: 200px;


--padding-zero: 0px;

/* Padding Left Variables */
--padding-left-5: 5px;
--padding-left-10: 10px;
--padding-left-15: 15px;
--padding-left-20: 20px;
--padding-left-25: 25px;
--padding-left-30: 30px;
--padding-left-35: 35px;
--padding-left-40: 40px;
--padding-left-45: 45px;
--padding-left-50: 50px;
--padding-left-55: 55px;
--padding-left-60: 60px;
--padding-left-65: 65px;
--padding-left-70: 70px;
--padding-left-75: 75px;
--padding-left-80: 80px;
--padding-left-85: 85px;
--padding-left-90: 90px;
--padding-left-95: 95px;
--padding-left-100: 100px;
--padding-left-100: 110px;
--padding-left-120: 120px;
--padding-left-130: 130px;
--padding-left-140: 140px;
--padding-left-150: 150px;
--padding-left-160: 160px;
--padding-left-170: 170px;
--padding-left-180: 180px;
--padding-left-190: 190px;
--padding-left-200: 200px;
--padding-left-240: 240px;
--padding-left-290: 290px;

/* Padding Top Variables */
--padding-top-5: 5px;
--padding-top-10: 10px;
--padding-top-15: 15px;
--padding-top-20: 20px;
--padding-top-25: 25px;
--padding-top-30: 30px;
--padding-top-35: 35px;
--padding-top-40: 40px;
--padding-top-45: 45px;
--padding-top-50: 50px;
--padding-top-55: 55px;
--padding-top-60: 60px;
--padding-top-65: 65px;
--padding-top-70: 70px;
--padding-top-75: 75px;
--padding-top-80: 80px;
--padding-top-85: 85px;
--padding-top-90: 90px;
--padding-top-95: 95px;
--padding-top-100: 100px;
--padding-top-110: 110px;
--padding-top-120: 120px;
--padding-top-130: 130px;
--padding-top-140: 140px;
--padding-top-150: 150px;

/* Padding Right Variables */
--padding-right-5: 5px;
--padding-right-10: 10px;
--padding-right-15: 15px;
--padding-right-20: 20px;
--padding-right-25: 25px;
--padding-right-30: 30px;
--padding-right-35: 35px;
--padding-right-40: 40px;
--padding-right-45: 45px;
--padding-right-50: 50px;
--padding-right-55: 55px;
--padding-right-60: 60px;
--padding-right-65: 65px;
--padding-right-70: 70px;
--padding-right-75: 75px;
--padding-right-80: 80px;
--padding-right-85: 85px;
--padding-right-90: 90px;
--padding-right-95: 95px;
--padding-right-100: 100px;
--padding-right-110: 110px;
--padding-right-150: 150px;
--padding-right-160: 160px;
--padding-right-170: 170px;
--padding-right-180: 180px;
--padding-right-200: 200px;
--padding-right-220: 220px;
--padding-right-240: 240px;
--padding-right-250: 250px;
--padding-right-260: 260px;

/* Padding Bottom Variables */
--padding-bottom-5: 5px;
--padding-bottom-10: 10px;
--padding-bottom-15: 15px;
--padding-bottom-20: 20px;
--padding-bottom-25: 25px;
--padding-bottom-30: 30px;
--padding-bottom-35: 35px;
--padding-bottom-40: 40px;
--padding-bottom-45: 45px;
--padding-bottom-50: 50px;
--padding-bottom-55: 55px;
--padding-bottom-60: 60px;
--padding-bottom-65: 65px;
--padding-bottom-70: 70px;
--padding-bottom-75: 75px;
--padding-bottom-80: 80px;
--padding-bottom-85: 85px;
--padding-bottom-90: 90px;
--padding-bottom-95: 95px;
--padding-bottom-100: 100px;
--padding-bottom-150: 150px;
--padding-bottom-200: 200px;
--padding-bottom-250: 250px;

}
