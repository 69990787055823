@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Titillium+Web:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600;700&display=swap");

/*global area*/
/*----------------------------------------------------*/
[dir="ltr"] {
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1.875;
    color: var(--body-color);
    font-family: var(--body-font);
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  .headline h1,
  .headline h2,
  .headline h3,
  .headline h4,
  .headline h5,
  .headline h6 {
    margin: 0;
    font-weight: normal;
    font-family: var(--heading);
  }

  a {
    cursor: pointer;
  }

  .feah-home {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1.875;
    color: var(--body-color-2);
    font-family: var(--body-font-2);
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  .feah-home .scrollup {
    background-color: var(--main-color-2);
  }

  .feah-home .container {
    max-width: 1425px;
  }

  .scrollup {
    width: 55px;
    right: 20px;
    z-index: 5;
    height: 55px;
    bottom: 20px;
    display: none;
    position: fixed;
    border-radius: 100%;
    line-height: 55px;
    background-color: var(--main-color);
  }

  .scrollup i {
    color: #fff;
  }

  .feah-home .scrollup {
    background-color: var(--main-color-2);
  }

  .headline-2 h1,
  .headline-2 h2,
  .headline-2 h3,
  .headline-2 h4,
  .headline-2 h5,
  .headline-2 h6 {
    margin: 0;
    font-weight: normal;
    font-family: var(--heading-2);
  }

  .no-padding {
    padding: 0;
  }

  .arck-appointment-form-wrap form button,
  .arck-btn a {
    z-index: 1;
    width: 195px;
    height: 60px;
    font-weight: 600;
    border-radius: 5px;
    position: relative;
    color: var(--white-color);
    font-family: var(--heading);
    background-color: var(--main-color);
  }

  .arck-appointment-form-wrap form button:before,
  .arck-btn a:before {
    top: 10px;
    left: 10px;
    z-index: -2;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    border-radius: 5px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    border: 1px solid var(--black-color);
  }

  .arck-appointment-form-wrap form button:after,
  .arck-btn a:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    content: "";
    height: 100%;
    border-radius: 5px;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color);
  }

  .arck-appointment-form-wrap form button:hover:before,
  .arck-btn a:hover:before {
    top: 0px;
    left: 0px;
  }

  .arck-appointment-form-wrap form button:hover:after,
  .arck-btn a:hover:after {
    background-color: var(--black-color);
  }

  @-webkit-keyframes Move_animation_1 {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-40px);
      transform: translateX(-40px);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(40px);
      transform: translateX(40px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes Move_animation_1 {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(-40px);
      transform: translateX(-40px);
    }

    50% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    75% {
      -webkit-transform: translateX(40px);
      transform: translateX(40px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .arck-btn-2 a {
    z-index: 1;
    color: #fff;
    width: 195px;
    height: 60px;
    font-weight: 600;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    font-family: var(--heading);
    background-color: var(--black-color);
  }

  .arck-btn-2 a:before {
    top: 17px;
    right: 45px;
    z-index: -1;
    width: 25px;
    content: "";
    height: 25px;
    position: absolute;
    border-radius: 100%;
    -webkit-transition: 800ms all ease;
    transition: 800ms all ease;
    background-color: var(--main-color);
  }

  .arck-btn-2 a:hover:before {
    -webkit-animation: Move_animation_1 12s infinite linear alternate;
    animation: Move_animation_1 12s infinite linear alternate;
  }

  .arck-section-title .sub-title,
  .arck-section-title-2 .sub-title {
    font-weight: 700;
    letter-spacing: 3px;
    color: var(--main-color);
    font-family: var(--heading);
  }

  .arck-section-title h2,
  .arck-section-title-2 h2 {
    font-size: 48px;
    font-weight: 600;
    padding: 10px 0px 20px;
    color: var(--black-color);
  }

  .arck-section-title.text-center,
  .arck-section-title-2.text-center {
    margin: 0 auto;
    max-width: 670px;
  }

  .arck-section-title-2 .sub-title {
    padding-left: 35px;
    position: relative;
  }

  .arck-section-title-2 .sub-title:before {
    top: 0;
    left: 2px;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url("/assets/img/shape/title-shape.png");
  }

  .arck-section-title-2 h2 {
    font-weight: 500;
    padding: 20px 0 30px;
  }

  .arck-section-title-2 h2 span {
    font-weight: 700;
  }

  .arck-section-title-2 p {
    line-height: 1.875;
  }

  .acrk-img-shape1,
  .acrk-img-shape2 {
    top: 0;
    right: 0;
    width: 200px;
    height: 155px;
  }

  .acrk-img-shape1:before,
  .acrk-img-shape1:after,
  .acrk-img-shape2:before,
  .acrk-img-shape2:after {
    top: 0;
    right: 0;
    content: "";
    width: 100px;
    height: 52px;
    position: absolute;
    background-color: var(--white-color);
  }

  .acrk-img-shape1:after,
  .acrk-img-shape2:after {
    bottom: 0;
    top: auto;
  }

  .acrk-img-shape1 i,
  .acrk-img-shape2 i {
    left: 2px;
    top: 52px;
    height: 52px;
    width: 100px;
    position: absolute;
    background-color: var(--white-color);
  }

  .acrk-img-shape2 {
    left: 0;
    bottom: 0;
    top: auto;
  }

  .acrk-img-shape2:before,
  .acrk-img-shape2:after {
    left: 0;
    right: auto;
  }

  .acrk-img-shape2 i {
    right: 2px;
    left: auto;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .arck-testimonial-slider .slick-dots li,
  .arck-project-slider .slick-dots li,
  .arck-service-details-testimonial-slider .slick-dots li {
    height: 5px;
    width: 25px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--black-color);
  }

  .arck-testimonial-slider .slick-dots li button,
  .arck-project-slider .slick-dots li button,
  .arck-service-details-testimonial-slider .slick-dots li button {
    display: none;
  }

  .arck-testimonial-slider .slick-dots li:hover,
  .arck-project-slider .slick-dots li:hover,
  .arck-service-details-testimonial-slider .slick-dots li:hover,
  .arck-testimonial-slider .slick-dots li.slick-active,
  .arck-project-slider .slick-dots li.slick-active,
  .arck-service-details-testimonial-slider .slick-dots li.slick-active {
    background-color: var(--main-color);
  }

  .inner-page-padding {
    padding: 80px 0px;
  }

  .arck-pagination {
    margin-top: 35px;
  }

  .arck-pagination li a {
    width: 45px;
    height: 45px;
    color: var(--black-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #dcdcdc;
  }

  .arck-pagination li a:hover,
  .arck-pagination li a.active {
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  .padding-top-120 {
    padding-top: 125px;
  }

  .arck-slider-inner-item {
    padding: 0 15px;
  }

  /*---------------------------------------------------- */
  /*Header area*/
  /*----------------------------------------------------*/
  .brand-logo {
    max-width: 200px;
  }

  .arck-header-section .navbar-nav {
    display: inherit;
  }

  .arck-header-section .dropdown {
    position: relative;
  }

  .arck-header-section .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    clip: inherit;
    -webkit-transform: scaleY(1);
    -khtml-transform: scaleY(1);
    transform: scaleY(1);
  }

  .arck-header-section .dropdown-menu {
    left: 0;
    top: 68px;
    z-index: 100;
    margin: 0px;
    padding: 20px 0;
    height: auto;
    min-width: 250px;
    display: block;
    border: none;
    border-radius: 0;
    position: absolute;
    opacity: 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: 0 0 0;
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    -o-transform-origin: 0 0 0;
    text-align: left;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .arck-header-section .dropdown-menu .dropdown-menu {
    top: 30px;
    left: 250px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 0 !important;
  }

  .arck-header-section .dropdown-menu .dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #1a2428;
  }

  .arck-header-section .dropdown-menu .dropdown-menu a:before {
    display: none;
  }

  .arck-header-section .dropdown-menu li {
    display: block;
    margin: 0 !important;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
  }

  .arck-header-section .dropdown-menu li:last-child {
    border-bottom: none;
  }

  .arck-header-section .dropdown-menu li:after {
    display: none;
  }

  .arck-header-section .dropdown-menu li:hover .dropdown-menu {
    top: 0;
    opacity: 1 !important;
  }

  .arck-header-section .dropdown-menu a {
    width: 100%;
    display: block;
    position: relative;
    padding: 10px 30px !important;
    color: #1a2428 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }

  .arck-header-section .dropdown-menu a:before {
    display: none;
  }

  .arck-header-section .dropdown-menu a:hover {
    color: var(--main-color) !important;
  }

  .arck-header-section .main-navigation li {
    margin: 0px 30px;
  }

  .arck-header-section .main-navigation li a {
    font-weight: 600;
    color: var(--white-color);
    text-transform: uppercase;
    padding-bottom: var(--padding-bottom-45);
  }

  .arck-header-section .main-navigation li:hover a {
    color: var(--main-color);
  }

  .arck-header-section.header-style-one {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 35px 60px;
    position: absolute;
    background-color: var(--black-color-2);
  }

  .arck-header-section.header-style-one.inner-page-header {
    background-color: rgba(31, 31, 31, 0.302);
  }

  .arck-header-section.header-style-one .header-cta-social {
    color: var(--white-color);
  }

  .arck-header-section.header-style-one .header-cta-social .header-cta {
    font-weight: 600;
  }

  .arck-header-section.header-style-one .header-cta-social .header-social {
    position: relative;
    margin-left: var(--margin-left-80);
  }

  .arck-header-section.header-style-one .header-cta-social .header-social:before {
    top: -10px;
    width: 1px;
    left: -30px;
    content: "";
    height: 40px;
    position: absolute;
    background-color: #363636;
  }

  .arck-header-section.header-style-one .header-cta-social .header-social li {
    margin-left: var(--margin-left-20);
  }

  .arck-header-section.header-style-one .header-cta-social .header-social li a:hover {
    color: var(--main-color);
  }

  .arck-header-section.header-style-one .header-side-btn {
    margin-left: 75px;
  }

  .arck-header-section.header-style-one .header-side-btn button {
    color: #fff;
    border: none;
    line-height: 1;
    font-size: 25px;
    position: relative;
    background-color: transparent;
  }

  .arck-header-section.header-style-one .header-side-btn button:before {
    top: -10px;
    width: 1px;
    left: -35px;
    content: "";
    height: 40px;
    position: absolute;
    background-color: #363636;
  }

  .arck-header-section.inner-page-header {
    background-color: #1c2834;
  }

  .arck-header-section.sticky-on {
    top: 0;
    width: 100%;
    z-index: 20;
    position: fixed;
    padding: 30px 60px;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    background-color: var(--black-color);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  }

  .arck-header-section.sticky-on .dropdown-menu {
    top: 60px;
  }

  .arck-header-section.sticky-on.inner-page-header {
    background-color: rgb(31, 31, 31);
  }

  /*Mobile area*/
  /*----------------------------------------------------*/
  .mobile_menu {
    display: none;
  }

  .mobile_logo {
    top: 10px;
    left: 15px;
    display: none;
    position: absolute;
  }

  .mobile_menu_content {
    top: 0px;
    bottom: 0;
    left: 0px;
    height: 100vh;
    z-index: 101;
    position: fixed;
    width: 310px;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px 35px 35px 35px;
    -webkit-box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
    box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
  }

  .mobile_menu_content .mobile-main-navigation {
    width: 100%;
  }

  .mobile_menu_content .mobile-main-navigation .navbar-nav {
    width: 100%;
  }

  .mobile_menu_content .dropdown:after {
    display: none;
  }

  .mobile_menu_content .navbar-nav .dropdown-menu {
    position: static !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .mobile_menu_content .mobile-main-navigation .navbar-nav li {
    width: 100%;
    display: block;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
  }

  .mobile_menu_content .mobile-main-navigation .navbar-nav li a {
    padding: 0;
    width: 100%;
    color: var(--black-color);
    display: block;
    font-weight: 600;
    font-size: 14px;
    font-family: var(--heading);
    text-transform: uppercase;
    padding: 10px 30px 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  .mobile_menu_content .m-brand-logo {
    width: 120px;
    margin: 50px auto;
  }

  .mobile_menu_wrap.mobile_menu_on .mobile_menu_content {
    left: 0px;
    -webkit-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
  }

  .mobile_menu_overlay {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    right: 0%;
    height: 120vh;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .mobile_menu_overlay_on {
    overflow: hidden;
  }

  .mobile_menu_wrap.mobile_menu_on .mobile_menu_overlay {
    opacity: 1;
    visibility: visible;
  }

  .mobile_menu_button {
    right: 15px;
    top: 20px;
    z-index: 5;
    color: #fff;
    display: none;
    font-size: 24px;
    cursor: pointer;
    line-height: 38px;
    position: absolute;
    text-align: center;
  }

  .mobile_menu .mobile-main-navigation .navbar-nav li a:after {
    display: none;
  }

  .mobile_menu .mobile-main-navigation .dropdown>.dropdown-menu {
    opacity: 1 !important;
    visibility: visible;
  }

  .mobile_menu .mobile_menu_content .mobile-main-navigation .navbar-nav .dropdown-menu {
    border: none;
    display: none;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 5px 0px;
    width: 100%;
    background-color: transparent;
  }

  .mobile_menu .mobile_menu_content .mobile-main-navigation .navbar-nav .dropdown-menu li {
    border: none;
    padding: 0 10px;
    line-height: 1;
  }

  .mobile_menu .mobile_menu_content .mobile-main-navigation .navbar-nav .dropdown-menu li:hover {
    background-color: transparent;
  }

  .mobile_menu .mobile_menu_content .mobile-main-navigation .navbar-nav .dropdown-menu li a {
    color: var(--black-color) !important;
  }

  .mobile_menu .mobile_menu_content .mobile-main-navigation .navbar-nav .dropdown-menu li a:hover {
    color: var(--main-color);
    background-color: transparent;
  }

  .mobile_menu .dropdown {
    position: relative;
  }

  .mobile_menu .dropdown .dropdown-btn {
    color: #9397a7;
    position: absolute;
    top: 3px;
    right: 0;
    height: 30px;
    padding: 5px 10px;
  }

  .mobile_menu .dropdown .dropdown-btn.toggle-open {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .mobile_menu .mobile_menu_close {
    color: #d60606;
    cursor: pointer;
    top: 25px;
    right: 25px;
    font-size: 20px;
    position: absolute;
  }

  /*// off canvas style*/
  /*----------------------------------------------------*/
  .sidebar-info-contents {
    position: relative;
  }

  .sidebar-info-contents .content-inner {
    position: relative;
  }

  .sidebar-info-contents .content-inner .logo {
    padding-bottom: 20px;
    max-width: 180px;
    border-bottom: 1px dashed #8e8e8e;
  }

  .sidebar-info-contents .content-inner .logo img {
    display: inline-block;
    max-width: 100%;
  }

  .sidebar-info-contents .content-inner .content-box {
    position: relative;
    margin-top: 50px;
  }

  .sidebar-info-contents .content-inner h5 {
    color: #fff;
    font-weight: 700;
    margin: 25px 0px 15px;
  }

  .sidebar-info-contents .content-inner .content-box .text {
    color: #fff;
    margin-bottom: 35px;
  }

  .sidebar-info-contents .content-inner .social-box {
    position: relative;
    margin-top: 0px;
    padding-left: 0;
    margin-bottom: 30px;
  }

  .sidebar-info-contents .content-inner .social-box li {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
  }

  .sidebar-info-contents .content-inner .social-box li a {
    position: relative;
    width: 36px;
    height: 36px;
    color: var(--black-color);
    z-index: 1;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
  }

  .sidebar-info-contents .content-inner .social-box li a:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  .close-side-widget,
  .close-side-widget:hover {
    color: #fff !important;
    font-size: 24px;
  }

  .xs-sidebar-group .xs-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    position: fixed;
    pointer-events: none;
    cursor: url(../img/cross-out.png), pointer;
  }

  .xs-sidebar-group .xs-overlay .loader-area {
    height: 100vh;
  }

  .xs-sidebar-group.info-group .xs-overlay {
    z-index: 35;
  }

  .xs-sidebar-group .preloader-wrap {
    padding: 0px;
  }

  .xs-sidebar-group .loader-area {
    height: 100vh;
  }

  .xs-sidebar-group.info-group.isActive .preloader-wrap .loader-bg {
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-transition: 800ms all cubic-bezier(0.77, 0, 0.175, 1);
    transition: 800ms all cubic-bezier(0.77, 0, 0.175, 1);
  }

  .xs-sidebar-group.info-group .preloader-wrap .loader-bg {
    left: 0;
    width: 0;
    opacity: 0;
    opacity: 0.95;
    height: 100%;
    -webkit-transition: 800ms all cubic-bezier(0.1, 0.1, 0.1, 1);
    transition: 800ms all cubic-bezier(0.1, 0.1, 0.1, 1);
  }

  .xs-sidebar-group .widget-heading {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px;
  }

  .feah-side-bar-menu-wrapper {
    align-items: center;
    display: flex;
  }

  .xs-sidebar-widget {
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    z-index: 999999;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #272727;
    -webkit-transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
    transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
    visibility: hidden;
    opacity: 0;
  }

  .xs-sidebar-group.isActive .xs-sidebar-widget {
    opacity: 1;
    visibility: visible;
    right: 0;
    -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
    transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
  }

  .sidebar-textwidget {
    padding: 40px;
  }

  .close-side-widget {
    color: #1768dd;
    font-size: rem(15px);
    display: block;
  }

  .sidebar-widget-container {
    position: relative;
    top: 150px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in 0.3s;
    transition: all 0.3s ease-in 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .xs-sidebar-group.isActive .sidebar-widget-container {
    top: 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 1s ease-out 1.2s;
    transition: all 1s ease-out 1.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .sidebar-info-contents .gallery-box li {
    margin-bottom: 5px;
  }

  .tl-thx-header-section.sticky-on {
    top: 0;
    width: 100%;
    z-index: 20;
    position: fixed;
    padding-top: 0;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    background-color: #fff;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  }

  /*---------------------------------------------------- */
  /*Slider area*/
  /*----------------------------------------------------*/
  .arck-slider-section-1 {
    overflow: hidden;
    z-index: 1;
    padding: 100px 55px 0px 55px;
    background-color: var(--black-color-2);
  }

  .arck-main-slider-item {
    z-index: 1;
    padding: 245px 150px 190px;
  }

  .arck-main-slider-item .arck-main-slider-img {
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }

  .arck-main-slider-item .arck-slider-main-text {
    max-width: 650px;
  }

  .arck-main-slider-item .arck-slider-main-text:before,
  .arck-main-slider-item .arck-slider-main-text:after {
    top: -160px;
    left: -80px;
    width: 2px;
    content: "";
    height: 350px;
    position: absolute;
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
  }

  .arck-main-slider-item .arck-slider-main-text:after {
    top: -283px;
    height: 400px;
    left: 41px;
    transform: rotate(90deg);
    width: 2px;
  }

  .arck-main-slider-item .arck-slider-main-text .line-shape {
    bottom: -80px;
    right: 30px;
    height: 350px;
    width: 350px;
  }

  .arck-main-slider-item .arck-slider-main-text .line-shape:before,
  .arck-main-slider-item .arck-slider-main-text .line-shape:after {
    bottom: 0;
    right: -80px;
    width: 2px;
    content: "";
    height: 0px;
    position: absolute;
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .arck-main-slider-item .arck-slider-main-text .line-shape:after {
    bottom: 50px;
    height: 2px;
    right: -135px;
    width: 2px;
  }

  .arck-main-slider-item .arck-slider-main-text .slider-sub-text {
    opacity: 0;
    color: var(--main-color);
    font-size: 18px;
    font-weight: 600;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item .arck-slider-main-text h1 {
    color: var(--white-color);
    font-size: 50px;
    font-weight: 600;
    line-height: 1.125;
    padding: 0px 0px 70px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item .arck-slider-main-text .arck-btn {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item .arck-slider-main-text .arck-btn a:before {
    border: 1px solid var(--white-color);
  }

  .arck-main-slider-item .arck-slider-main-text .arck-btn a:hover::after {
    background-color: var(--white-color);
  }

  .arck-main-slider-item .arck-slider-main-text .arck-btn a:hover {
    color: var(--black-color);
  }

  .arck-main-slider-item.slick-current.slick-active .img-zooming {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transition: all 7000ms linear;
    transition: all 7000ms linear;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text:before {
    height: 300px;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text:after {
    width: 300px;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text .line-shape:before {
    height: 300px;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text .line-shape:after {
    width: 300px;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text .slider-sub-text {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 900ms;
    transition-delay: 900ms;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text h1 {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 1200ms;
    transition-delay: 1200ms;
  }

  .arck-main-slider-item.slick-current.slick-active .arck-slider-main-text .arck-btn {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 1500ms;
    transition-delay: 1500ms;
  }

  .arck-slider-area .slick-prev,
  .arck-slider-area .slick-next {
    display: none !important;
  }

  .arck-slider-area .slick-dots {
    top: 50%;
    left: auto;
    width: auto;
    right: 120px;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .arck-slider-area .slick-dots li {
    width: 5px;
    height: 25px;
    display: block;
    margin-bottom: 10px;
    background-color: var(--white-color);
  }

  .arck-slider-area .slick-dots li.slick-active {
    background-color: var(--main-color);
  }

  .arck-slider-section-2 {
    overflow: hidden;
    background-color: #fbfbfb;
  }

  .arck-slider-section-2 .slider-social {
    left: 50px;
    z-index: 2;
    bottom: 50px;
  }

  .arck-slider-section-2 .slider-social a {
    margin-left: 15px;
    color: var(--black-color);
  }

  .arck-slider-section-2 .slider-social a:hover {
    color: var(--main-color);
  }

  .arck-main-slider-item-2 {
    padding: 350px 0px 215px;
  }

  .arck-main-slider-item-2 .arck-slider-img-2 {
    top: 0;
    right: 0;
    width: 48%;
    height: 100%;
  }

  .arck-main-slider-item-2 .arck-slider-img-2 img {
    width: 100%;
    height: 100%;
  }

  .arck-main-slider-item-2 .slider-shape {
    top: 25%;
    opacity: 0;
    left: 160px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item-2 .arck-slider-main-text {
    max-width: 530px;
  }

  .arck-main-slider-item-2 .arck-slider-main-text .slider-sub-text {
    opacity: 0;
    color: var(--main-color);
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 3px;
    font-family: var(--heading);
    padding-left: 35px;
    position: relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item-2 .arck-slider-main-text .slider-sub-text:before {
    top: 0;
    left: 2px;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url("/assets/img/shape/title-shape.png");
  }

  .arck-main-slider-item-2 .arck-slider-main-text h1 {
    opacity: 0;
    color: var(--black-color);
    font-size: 70px;
    line-height: 1.071;
    font-weight: 600;
    padding: 23px 0px 30px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item-2 .arck-slider-main-text h1 span {
    font-weight: 700;
  }

  .arck-main-slider-item-2 .arck-slider-main-text p {
    opacity: 0;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 40px;
    font-family: var(--heading);
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item-2 .arck-slider-main-text .arck-btn-2 {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  .arck-main-slider-item-2.slick-current.slick-active .arck-slider-main-text .slider-sub-text {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
  }

  .arck-main-slider-item-2.slick-current.slick-active .arck-slider-main-text h1 {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 600ms;
    transition-delay: 600ms;
  }

  .arck-main-slider-item-2.slick-current.slick-active .arck-slider-main-text p {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 800ms;
    transition-delay: 800ms;
  }

  .arck-main-slider-item-2.slick-current.slick-active .arck-slider-main-text .arck-btn-2 {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 1000ms;
    transition-delay: 1000ms;
  }

  .arck-main-slider-item-2.slick-current.slick-active .slider-shape {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    -webkit-transition-delay: 1200ms;
    transition-delay: 1200ms;
  }

  .arck-main-slider-area-2 .carousel_nav {
    top: 50%;
    left: -100px;
    z-index: 2;
    position: absolute;
    -webkit-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
  }

  .arck-main-slider-area-2 .carousel_nav:before,
  .arck-main-slider-area-2 .carousel_nav:after {
    top: -15px;
    width: 2px;
    content: "";
    height: 60px;
    right: 140px;
    position: absolute;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: var(--black-color);
  }

  .arck-main-slider-area-2 .carousel_nav:after {
    top: 0px;
    height: 30px;
    right: 118px;
    background-color: var(--main-color);
  }

  .arck-main-slider-area-2 .carousel_nav button {
    color: var(--black-color);
    padding: 0;
    border: none;
    font-weight: 600;
    margin: 0 50px;
    font-family: var(--heading);
    background-color: transparent;
  }

  .arck-main-slider-area-2 .carousel_nav button:hover {
    color: var(--main-color);
  }

  /*---------------------------------------------------- */
  /*Service area*/
  /*----------------------------------------------------*/
  .arck-service-section {
    padding: 130px 0px;
    background-color: #f4f4f4;
  }

  .arck-service-content {
    margin: 0px -15px;
    padding: 0px 15px;
    background-color: var(--white-color);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
    box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
  }

  .arck-service-item {
    z-index: 1;
    overflow: hidden;
    padding: 70px 45px 95px;
  }

  .arck-service-item:before,
  .arck-service-item:after {
    top: 38px;
    left: 20px;
    content: "";
    height: 2px;
    width: 100px;
    position: absolute;
    background-color: #efefef;
  }

  .arck-service-item:after {
    top: 20px;
    width: 2px;
    left: 38px;
    height: 100px;
  }

  .arck-service-item .hover-img {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    overflow: hidden;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item .hover-img:before {
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .arck-service-item .hover-img img {
    width: 100%;
    height: 100%;
  }

  .arck-service-item .serial-no {
    right: 20px;
    bottom: 20px;
    color: #e8e8e8;
    font-size: 90px;
    line-height: 0.75;
    font-weight: 700;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    font-family: var(--heading);
  }

  .arck-service-item .inner-icon {
    z-index: 1;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: var(--margin-bottom-50);
  }

  .arck-service-item .inner-icon:before {
    z-index: -1;
    width: 50px;
    content: "";
    right: -15px;
    height: 45px;
    bottom: -15px;
    position: absolute;
    border: 5px solid #ebebeb;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item .arck-inner-text {
    position: relative;
  }

  .arck-service-item .arck-inner-text h3 {
    font-weight: 700;
    color: var(--black-color);
    font-size: var(--font-24);
    padding-bottom: var(--padding-bottom-20);
  }

  .arck-service-item .arck-inner-text p {
    line-height: 1.625;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    padding-bottom: var(--padding-bottom-20);
  }

  .arck-service-item .arck-inner-text .btn-more {
    font-weight: 600;
    color: var(--black-color);
    font-family: var(--heading);
  }

  .arck-service-item .arck-inner-text .btn-more i {
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item .arck-inner-text .btn-more:hover {
    color: var(--main-color);
  }

  .arck-service-item .arck-inner-text .btn-more:hover i {
    margin-left: var(--margin-left-10);
  }

  .arck-service-item:hover .hover-img,
  .arck-service-item.active .hover-img {
    opacity: 1;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .arck-service-item:hover .serial-no,
  .arck-service-item.active .serial-no {
    color: #fff;
  }

  .arck-service-item:hover .arck-inner-text h3,
  .arck-service-item.active .arck-inner-text h3 {
    color: #fff;
  }

  .arck-service-item:hover .arck-inner-text p,
  .arck-service-item.active .arck-inner-text p {
    color: #fff;
  }

  .arck-service-item:hover .arck-inner-text .btn-more,
  .arck-service-item.active .arck-inner-text .btn-more {
    color: #fff;
  }

  .arck-service-item:hover .arck-inner-text .btn-more:hover,
  .arck-service-item.active .arck-inner-text .btn-more:hover {
    color: var(--main-color);
  }

  .arck-service-item:hover .inner-icon:before,
  .arck-service-item.active .inner-icon:before {
    opacity: 0.4;
  }

  .arck-service-content-2 {
    padding-top: 40px;
  }

  .arck-service-item-2 {
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 50px 35px 45px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
    box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
  }

  .arck-service-item-2 .service-shape {
    top: -15px;
    right: -10px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item-2 .inner-icon {
    z-index: 1;
    width: 80px;
    height: 70px;
    margin-bottom: 40px;
    border: 5px solid #ebebeb;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item-2 .inner-icon:before {
    left: 0;
    right: 0;
    top: -5px;
    content: "";
    z-index: -1;
    width: 28px;
    height: 116%;
    margin: 0 auto;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--white-color);
  }

  .arck-service-item-2 .inner-icon:after {
    top: 50%;
    left: -5px;
    content: "";
    z-index: -1;
    width: 116%;
    height: 28px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--white-color);
  }

  .arck-service-item-2 .inner-text h3 {
    font-weight: 600;
    padding-bottom: 18px;
    color: #1a1a1a;
    font-size: var(--font-24);
  }

  .arck-service-item-2 .inner-text p {
    line-height: 1.75;
    padding-bottom: 30px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-service-item-2:hover {
    background-color: var(--black-color);
  }

  .arck-service-item-2:hover .inner-icon {
    border: 5px solid #2f2f2f;
  }

  .arck-service-item-2:hover .inner-icon:before,
  .arck-service-item-2:hover .inner-icon:after {
    background-color: var(--black-color);
  }

  .arck-service-item-2:hover .inner-text h3,
  .arck-service-item-2:hover .inner-text p,
  .arck-service-item-2:hover .inner-text .read-more-btn {
    color: var(--white-color);
  }

  .arck-service-details-category li {
    margin-bottom: 10px;
  }

  .arck-service-details-category li a {
    color: var(--black-color);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 25px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: var(--heading);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.08);
    box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.08);
  }

  .arck-service-details-category li a:hover,
  .arck-service-details-category li a.active {
    color: var(--white-color) !important;
    background-color: var(--main-color);
  }

  .arck-project-details-slider .slick-prev,
  .arck-service-details-slider .slick-prev,
  .arck-project-details-slider .slick-next,
  .arck-service-details-slider .slick-next {
    color: var(--white-color);
    width: 40px;
    z-index: 2;
    height: 40px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--black-color);
  }

  .arck-project-details-slider .slick-prev:hover,
  .arck-service-details-slider .slick-prev:hover,
  .arck-project-details-slider .slick-next:hover,
  .arck-service-details-slider .slick-next:hover {
    background-color: var(--main-color);
  }

  .arck-project-details-slider .slick-next,
  .arck-service-details-slider .slick-next {
    right: 18px;
  }

  .arck-project-details-slider .slick-prev,
  .arck-service-details-slider .slick-prev {
    left: 15px;
  }

  .arck-project-details-slider .slick-prev.slick-disabled:before,
  .arck-service-details-slider .slick-prev.slick-disabled:before,
  .arck-project-details-slider .slick-next.slick-disabled:before,
  .arck-service-details-slider .slick-next.slick-disabled:before {
    opacity: 1;
  }

  .arck-service-details-slider {
    margin: 0px -15px;
  }

  .service-details-img-item {
    padding: 0px 15px;
  }

  .arck-service-details-progress-wrap {
    margin-bottom: 60px;
  }

  .arck-service-details-text-area {}

  .arck-service-details-text-area h3 {
    color: var(--black-color);
    font-size: 42px;
    font-weight: 600;
    padding-bottom: 35px;
  }

  .arck-service-details-text-area p {
    line-height: 1.875;
    padding-bottom: 30px;
  }

  .arck-service-details-progress {
    padding: 40px 30px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.08);
    box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.08);
  }

  .arck-service-details-progress h3,
  .arck-service-details-progress h4 {
    font-size: 36px;
    color: var(--black-color);
    font-weight: 600;
  }

  .arck-service-details-progress h4 {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .arck-service-details-progress p {
    line-height: 1.875;
  }

  .arck-service-details-progress .arck-skill-progress-bar {
    padding-top: 25px;
  }

  .arck-service-details-progress .arck-skill-progress-bar .progress span {
    display: none;
  }

  .arck-service-details-progress .arck-skill-progress-bar .skill-set-percent {
    margin-bottom: 20px;
  }

  .service-details-img-wrap {
    margin-bottom: 55px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap li,
  .arck-service-details-img-list-wrap li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 25px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap li:before,
  .arck-service-details-img-list-wrap li:before {
    top: 0;
    left: 0;
    content: "\f058";
    position: absolute;
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
  }

  .arck-service-details-img-list-wrap p {
    line-height: 1.875;
    padding-bottom: 30px;
  }

  /*---------------------------------------------------- */
  /*About area*/
  /*----------------------------------------------------*/
  .arck-about-section {
    overflow: hidden;
    padding: 130px 0px;
  }

  .arck-about-text-wrapper {
    padding-top: 30px;
    max-width: 540px;
  }

  .arck-about-text-wrapper .about-bg {
    right: 50px;
    top: 0px;
  }

  .arck-about-text-wrapper .arck-year-experience {
    padding: 40px 0px;
  }

  .arck-about-text-wrapper .arck-year-experience h3 {
    font-size: 90px;
    font-weight: 700;
    padding: 30px 45px;
    margin-right: 35px;
    color: var(--main-color);
    background-color: var(--white-color);
    -webkit-box-shadow: 0px 0px 41px 0px rgba(35, 31, 32, 0.1);
    box-shadow: 0px 0px 41px 0px rgba(35, 31, 32, 0.1);
  }

  .arck-about-text-wrapper .arck-year-experience p {
    color: #333333;
    font-size: 24px;
    max-width: 145px;
  }

  .arck-about-img-wrapper {
    z-index: 1;
  }

  .arck-about-img-wrapper .shape1 {
    z-index: -1;
    right: -40px;
    bottom: -40px;
  }

  .arck-about-section-2 {
    overflow: hidden;
    padding: 160px 0 215px;
  }

  .arck-about-section-2 .about-shape-1 {
    left: 0;
    z-index: -1;
    right: 255px;
    bottom: 125px;
    margin: 0 auto;
    text-align: center;
  }

  .arck-about-text-wrap-2 {
    padding-top: 25px;
    padding-left: 40px;
  }

  .about-signature-img {
    padding-top: 40px;
    margin-bottom: 60px;
  }

  .about-signature-img .inner-img {
    /* width: 120px;
  height: 100px;
  overflow: hidden;
  margin-right: 25px;
  border-radius: 10px;*/
  }

  .about-signature-img .inner-text h3 {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 600;
    max-width: 360px;
    line-height: 1.667;
  }

  .about-signature-img .sign-img {
    right: -45px;
    bottom: -40px;
  }

  .about-signature-img .about-author {
    margin-top: 20px;
  }

  .about-signature-img .about-author h4 {
    color: var(--black-color);
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .about-signature-img .about-author span {
    font-size: 14px;
  }

  .about-page-about-section {
    padding: 80px 0px 60px;
    background-color: #fff !important;
  }

  .about-page-about-content .arck-about-img-wrapper .shape1 {
    left: -40px;
    right: auto;
  }

  .about-page-about-content .acrk-img-shape2 {
    top: 0;
    left: 0;
    bottom: 0;
  }

  .about-page-about-content .acrk-img-shape1 {
    right: 0;
    top: auto;
    bottom: 0;
  }

  /*---------------------------------------------------- */
  /*Project area*/
  /*----------------------------------------------------*/
  .arck-project-section {
    padding-top: 40px;
    padding-bottom: 110px;
  }

  .arck-project-slider-wrapper {
    padding: 0px 45px;
  }

  .arck-project-top-content {
    padding-bottom: var(--padding-bottom-60);
  }

  .arck-project-top-content .arck-section-title h2 {
    padding: 0;
  }

  .arck-project-filter-btn li,
  .arck-project-filter-btn button,
  .arck-project-filter-btn li,
  .arck-project-filter-btn button {
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
    color: var(--black-color);
    margin-left: var(--margin-left-30);
  }

  .arck-project-filter-btn li.active,
  .arck-project-filter-btn button.active,
  .arck-project-filter-btn li.active,
  .arck-project-filter-btn button.active {
    color: var(--main-color);
  }

  .arck-project-filter-btn li.is-checked,
  .arck-project-filter-btn button.is-checked,
  .arck-project-filter-btn li.is-checked,
  .arck-project-filter-btn button.is-checked {
    color: var(--main-color);
  }

  .arck-project-filter-btn button,
  .arck-project-filter-btn button {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .arck-project-item {
    margin: 15px;
    overflow: hidden;
  }

  .arck-project-item:before {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 1;
    content: "";
    height: 100%;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: rgba(0, 0, 0, 0.7);
  }

  .arck-project-item .inner-img {
    overflow: hidden;
  }

  .arck-project-item .inner-img img {
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-project-item .inner-text {
    top: 95px;
    left: 40px;
    z-index: 2;
    position: absolute;
  }

  .arck-project-item .inner-text .project-title-desc {
    max-width: 340px;
  }

  .arck-project-item .inner-text .project-title-desc span {
    opacity: 0;
    font-weight: 700;
    font-size: var(--font-14);
    color: var(--main-color);
    display: inline-block;
    margin-bottom: var(--margin-bottom-15);
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .arck-project-item .inner-text .project-title-desc h3 {
    opacity: 0;
    font-weight: 700;
    color: var(--white-color);
    font-size: var(--font-28);
    padding-bottom: var(--padding-bottom-25);
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .arck-project-item .inner-text .project-title-desc p {
    opacity: 0;
    line-height: 1.75;
    color: var(--white-color);
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .arck-project-item .view-more-btn {
    opacity: 0;
    left: 20px;
    z-index: 2;
    bottom: 70px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-project-item .view-more-btn a {
    font-weight: 600;
    color: var(--main-color);
    font-family: var(--heading);
  }

  .arck-project-item .view-more-btn a i {
    margin-left: var(--margin-left-5);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-project-item .view-more-btn a:hover i {
    margin-left: var(--margin-left-10);
  }

  .arck-project-item:hover:before {
    opacity: 1;
  }

  .arck-project-item:hover .inner-text .project-title-desc span,
  .arck-project-item:hover .inner-text .project-title-desc h3,
  .arck-project-item:hover .inner-text .project-title-desc p {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  .arck-project-item:hover .view-more-btn {
    opacity: 1;
    left: 40px;
  }

  .arck-project-item:hover .inner-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .arck-project-slider .slick-slide {
    -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
    opacity: 0;
  }

  .arck-project-slider .slick-track .slick-active {
    animation: zoomIn 600ms ease-out infinite;
    -webkit-animation: zoomIn 600ms ease-out infinite;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  .arck-project-slider .slick-slide.slick-active {
    opacity: 1;
  }

  .arck-project-slider .slick-dots {
    margin-top: 40px;
    position: static;
  }

  .arck-project-section-2 {
    padding-top: 80px;
  }

  .arck-project-item-2:before {
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    content: "";
    z-index: 1;
    height: 100%;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: rgba(0, 0, 0, 0.7);
  }

  .arck-project-item-2 .inner-img img {
    width: 100%;
    height: 100%;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-project-item-2 .inner-text {
    left: 0px;
    z-index: 2;
    opacity: 0;
    bottom: 50px;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-project-item-2 .inner-text h3 {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
    color: var(--white-color);
  }

  .arck-project-item-2 .inner-text span {
    font-size: 14px;
    font-weight: 600;
    color: var(--main-color);
  }

  .arck-project-item-2:hover:before {
    opacity: 1;
  }

  .arck-project-item-2:hover .inner-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .arck-project-item-2:hover .inner-text {
    left: 50px;
    opacity: 1;
  }

  .arck-project-filter-content {
    padding: 0px 60px;
  }

  .filtr-container-area .grid-sizer {
    width: 25%;
  }

  .filtr-container-area .grid-sizer,
  .filtr-container-area .grid-item {
    float: left;
    overflow: hidden;
    position: relative;
    display: inline-block;
  }

  .filtr-container-area .grid-sizer img,
  .filtr-container-area .grid-item img {
    width: 100%;
  }

  .filtr-container-area .grid-size-25 {
    width: 25%;
  }

  .filtr-container-area .grid-size-50 {
    width: 50%;
  }

  .filtr-container-area .grid-size-75 {
    width: 75%;
  }

  .filtr-container-area .grid_2-sizer {
    width: 41.2%;
  }

  .filtr-container-area .grid_2-sizer,
  .filtr-container-area .grid_2-item {
    float: left;
    overflow: hidden;
    position: relative;
    display: inline-block;
  }

  .filtr-container-area .grid_2-sizer img,
  .filtr-container-area .grid_2-item img {
    width: 100%;
  }

  .filtr-container-area .grid_2-size-40 {
    width: 41.2%;
  }

  .filtr-container-area .grid_2-size-60 {
    width: 58.8%;
  }

  .arck-project-feed-section .arck-project-filter-btn {
    padding-bottom: 55px;
  }

  .arck-project-feed-content-2 {
    margin: 0px -15px;
  }

  .arck-project-feed-item-2 {
    margin: 15px;
    overflow: hidden;
  }

  .arck-project-feed-item-2 .arck-project-item-2 .inner-text {
    bottom: 30px;
  }

  .arck-project-feed-item-2 .arck-project-item-2:hover .inner-text {
    left: 30px;
  }

  .arck-project-details-img-slider {
    margin-bottom: 55px;
  }

  .arck-project-details-text-wrapper {
    padding-bottom: 60px;
  }

  .arck-project-details-slider .slick-next {
    right: 6px;
  }

  .arck-project-details-slider .slick-prev {
    left: 0;
  }

  .arck-project-details-text h3,
  .arck-project-details-text h4 {
    font-size: 42px;
    font-weight: 600;
    padding-bottom: 35px;
    color: var(--black-color);
  }

  .arck-project-details-text h4 {
    font-size: 36px;
    padding-bottom: 25px;
  }

  .arck-project-details-text p {
    line-height: 1.875;
    padding-bottom: 35px;
  }

  .arck-project-details-text li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 25px;
  }

  .arck-project-details-text li:before {
    top: 0;
    left: 0;
    content: "\f058";
    position: absolute;
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
  }

  .arck-project-details-feature {
    padding: 50px 40px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-project-details-feature h3 {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 40px;
  }

  .arck-project-details-feature .feature-list li {
    width: 100%;
    color: var(--black-color);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 13px;
    padding-bottom: 13px;
    font-family: var(--heading);
    border-bottom: 1px solid #dedede;
  }

  .arck-project-details-feature .feature-list li span {
    float: right;
    font-weight: 400;
    color: var(--body-color);
  }

  .arck-project-details-feature .project-share {
    margin-top: 30px;
  }

  .arck-project-details-feature .project-share .title {
    color: var(--black-color);
    font-weight: 700;
  }

  .arck-project-details-feature .project-share .share-social a {
    color: var(--black-color);
    margin-left: 5px;
  }

  .arck-project-details-feature .project-share .share-social a:hover {
    color: var(--main-color);
  }

  .arck-project-details-img-wrapper {
    padding-bottom: 55px;
  }

  .arck-project-next-prev-btn {
    margin-top: 80px;
  }

  .arck-project-next-prev-btn a {
    font-weight: 600;
    font-family: var(--heading);
  }

  .arck-project-next-prev-btn a i {
    margin-right: 15px;
  }

  .arck-project-next-prev-btn a:hover {
    color: var(--main-color);
  }

  .arck-project-next-prev-btn a:nth-child(2) i {
    margin-right: 0;
    margin-left: 15px;
  }

  .arck-project-section-2 .arck-project-top-content {
    padding-bottom: 70px;
  }

  /*---------------------------------------------------- */
  /*Work Process area*/
  /*----------------------------------------------------*/
  .arck-work-process-section {
    padding-bottom: 150px;
  }

  .arck-work-process-content {
    padding-top: 85px;
  }

  .arck-work-process-item {
    z-index: 1;
    padding: 50px 30px 0px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
    box-shadow: 0px 0px 50px 0px rgba(35, 31, 32, 0.1);
  }

  .arck-work-process-item:before {
    left: 0;
    right: 0;
    top: -20px;
    z-index: -2;
    width: 85%;
    content: "";
    height: 111%;
    margin: 0 auto;
    position: absolute;
    border: 2px solid #e5e5e5;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-work-process-item:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-color: #fff;
  }

  .arck-work-process-item .inner-icon {
    margin-bottom: 30px;
  }

  .arck-work-process-item .inner-text h3 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 20px;
    color: var(--black-color);
  }

  .arck-work-process-item .inner-text p {
    padding-bottom: 15px;
  }

  .arck-work-process-item .work-serial {
    top: 20px;
    width: 110px;
    height: 90px;
    margin: 0 auto;
    padding-top: 10px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--main-color);
  }

  .arck-work-process-item .work-serial h4 {
    font-size: 36px;
    font-weight: 700;
    color: var(--white-color);
  }

  .arck-work-process-item .work-serial span {
    color: #fff;
    font-weight: 600;
    font-family: var(--heading);
  }

  .arck-work-process-item:hover:before {
    border: 2px solid var(--main-color);
    background-color: var(--main-color);
  }

  .arck-work-process-item:hover .work-serial {
    background-color: var(--black-color);
  }

  /*---------------------------------------------------- */
  /*Counter area*/
  /*----------------------------------------------------*/
  .arck-counter-section {
    padding: 70px 0px 75px;
    background-color: #f4f4f4;
  }

  .arck-counter-inner-item {
    padding-top: 80px;
  }

  .arck-counter-inner-item:before {
    top: 0;
    left: 0;
    right: 0;
    width: 2px;
    content: "";
    height: 50px;
    margin: 0 auto;
    position: absolute;
    background-color: var(--main-color);
  }

  .arck-counter-inner-item:after {
    top: 50px;
    left: 0;
    right: 0;
    width: 10px;
    content: "";
    height: 10px;
    margin: 0 auto;
    position: absolute;
    border-radius: 100%;
    background-color: var(--main-color);
  }

  .arck-counter-inner-item h3 {
    z-index: 1;
    font-size: 42px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    color: var(--main-color);
  }

  .arck-counter-inner-item h3:after {
    left: 0;
    z-index: -1;
    content: "";
    width: 100%;
    bottom: 10px;
    height: 10px;
    position: absolute;
    background-color: #c0bdbd;
  }

  .arck-counter-inner-item p {
    font-weight: 600;
    padding-top: 5px;
    font-size: var(--font-22);
    color: var(--black-color);
    font-family: var(--heading);
  }

  /*---------------------------------------------------- */
  /*Team area*/
  /*----------------------------------------------------*/
  .arck-team-section {
    z-index: 1;
    overflow: hidden;
    padding: 125px 0px 100px;
  }

  .arck-team-section:before,
  .arck-team-section:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    content: "";
    height: 605px;
    position: absolute;
    background: url("/assets/img/bg/tm-bg.jpg");
  }

  .arck-team-section:after {
    background: rgba(0, 0, 0, 0.6);
  }

  .arck-team-section .arck-section-title h2,
  .arck-team-section .arck-section-title p {
    color: #fff;
  }

  .arck-team-content {
    margin: 0px -15px;
    padding-top: 75px;
  }

  .arck-team-item {
    overflow: hidden;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .arck-team-item:before {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: rgba(0, 0, 0, 0.7);
  }

  .arck-team-item .inner-img img {
    -webkit-transition: var(--transition);
    transition: var(--transition);
    filter: grayscale(100%) !important;
  }

  .arck-team-item .inner-img img:hover {
    filter: grayscale(0%) !important;
  }

  .arck-team-item .inner-text {
    left: 0;
    right: 0;
    top: 70px;
    z-index: 1;
    opacity: 0;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .arck-team-item .inner-text h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .arck-team-item .inner-text span {
    color: #fff;
  }

  .arck-team-item .inner-social {
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    bottom: 70px;
    margin: 0 auto;
    position: absolute;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .arck-team-item .inner-social a {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--white-color);
  }

  .arck-team-item .inner-social a:hover {
    color: var(--white-color);
    background-color: var(--main-color);
  }

  .arck-team-item:hover .inner-text {
    opacity: 1;
    top: 112px;
  }

  .arck-team-item:hover:before {
    opacity: 1;
  }

  .arck-team-item:hover .inner-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .arck-team-item:hover .inner-social {
    opacity: 1;
    bottom: 90px;
  }

  .arck-team-details-content {
    margin-bottom: 65px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
  }

  .arck-team-details-text-wrap {
    padding: 75px 30px;
  }

  .arck-team-details-text-wrap .about-bg {
    right: 25px;
    bottom: 20px;
  }

  .arck-team-details-text-wrap h3 {
    font-size: 36px;
    color: var(--black-color);
    font-weight: 600;
    padding-bottom: 12px;
  }

  .arck-team-details-text-wrap .arck-team-details-info {
    padding-top: 35px;
    max-width: 350px;
  }

  .arck-team-details-text-wrap .arck-team-details-info li {
    font-weight: 600;
    font-family: var(--heading);
    color: var(--black-color);
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .arck-team-details-text-wrap .arck-team-details-info li span {
    float: right;
    font-weight: 400;
    text-transform: capitalize;
  }

  .arck-team-details-text-wrap .arck-team-details-social {
    margin-top: 40px;
  }

  .arck-team-details-text-wrap .arck-team-details-social a {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--black-color);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-team-details-text-wrap .arck-team-details-social a:hover {
    color: var(--white-color);
    background-color: var(--main-color);
  }

  .arck-team-details-text-content-wrap h3 {
    color: var(--black-color);
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 30px;
  }

  .arck-team-details-text-content-wrap p {
    line-height: 1.875;
    padding-bottom: 30px;
  }

  .arck-team-details-skill {
    max-width: 500px;
  }

  .arck-team-details-skill .arck-skill-progress-bar {
    padding-top: 0;
  }

  /*---------------------------------------------------- */
  /*Appointment-Video area*/
  /*----------------------------------------------------*/
  .arck-appointment-video-section {
    z-index: 1;
    padding: 120px 0px 130px;
    background-color: var(--black-color);
  }

  .arck-appointment-video-section .side-icon {
    bottom: 0;
    left: 65px;
    z-index: -1;
  }

  .arck-video-play-wrap {
    top: 0;
    right: 0;
    width: 48%;
    height: 100%;
  }

  .arck-video-play-wrap:after {
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .arck-video-play-wrap .arck-video-img-wrap {
    display: none;
  }

  .arck-video-play-btn {
    left: 0;
    top: 45%;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .arck-video-play-btn a {
    width: 85px;
    height: 85px;
    margin: 0 auto;
    border-radius: 100%;
    font-size: var(--font-20);
    color: var(--main-color);
    border: 3px solid var(--white-color);
  }

  .arck-appointment-form-wrap {
    max-width: 570px;
  }

  .arck-appointment-form-wrap .arck-section-title {
    padding-bottom: 30px;
  }

  .arck-appointment-form-wrap .arck-section-title h2 {
    color: var(--white-color);
  }

  .arck-appointment-form-wrap form input,
  .arck-appointment-form-wrap form textarea,
  .arck-appointment-form-wrap form .selet1 .p-dropdown {
    width: 100%;
    height: 55px;
    border: none;
    color: #373737;
    padding-left: 20px;
    border-radius: 5px;

  }

  .p-dropdown {
    background-color: var(--white-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    height: 55px;
    border: 0;
    width: 100%;
    padding: 18px;
  }

  .p-toast .p-toast-message {
    border-radius: 32px !important;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0px 0 1rem;
    padding-inline-start: 20px;
    border-inline-start: 3px solid #00000006;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    overflow-wrap: anywhere;
  }

  .p-toast .p-toast-message .p-toast-message-content {
    align-items: center;
  }

  .arck-appointment-form-wrap form input::-webkit-input-placeholder,
  .arck-appointment-form-wrap form textarea::-webkit-input-placeholder {
    color: rgb(108, 108, 108);
  }

  .arck-appointment-form-wrap form input::-moz-placeholder,
  .arck-appointment-form-wrap form textarea::-moz-placeholder {
    color: rgb(108, 108, 108);
  }

  .arck-appointment-form-wrap form input:-ms-input-placeholder,
  .arck-appointment-form-wrap form textarea:-ms-input-placeholder {
    color: rgb(108, 108, 108);
  }

  .arck-appointment-form-wrap form input::-ms-input-placeholder,
  .arck-appointment-form-wrap form textarea::-ms-input-placeholder {
    color: rgb(108, 108, 108);
  }

  .arck-appointment-form-wrap form input::placeholder,
  .arck-appointment-form-wrap form textarea::placeholder {
    color: rgb(108, 108, 108);
  }

  .arck-appointment-form-wrap form textarea {
    height: 105px;
    padding-top: 15px;
  }

  .arck-appointment-form-wrap form button {
    border: none;
    text-transform: uppercase;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .arck-appointment-form-wrap form button:before {
    border: 1px solid var(--white-color);
  }

  .arck-appointment-form-wrap form button:hover {
    color: var(--main-color);
  }

  .arck-appointment-form-wrap form button:hover:after {
    background-color: var(--white-color);
  }

  .arck-video-cta-wrap {
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background-color: #2d2d2d;
  }

  .arck-video-cta-wrap .video-cta-item {
    width: 33.33%;
    padding: 30px 25px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-video-cta-wrap .video-cta-item.active {
    background-color: var(--main-color);
  }

  .arck-video-cta-wrap .video-cta-item .inner-icon {
    margin-right: 20px;
  }

  .arck-video-cta-wrap .video-cta-item .inner-text {
    width: 180px;
  }

  .arck-video-cta-wrap .video-cta-item .inner-text p {
    color: var(--white-color);
    max-width: 316px;
  }

  .arck-video-cta-wrap .video-cta-item .inner-text h3 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 8px;
    color: var(--white-color);
  }

  /*---------------------------------------------------- */
  /*Blog area*/
  /*----------------------------------------------------*/
  .arck-blog-item .inner-text .read-more-btn,
  .arck-blog-item-2 .inner-text .read-more-btn,
  .arck-service-item-2 .inner-text .read-more-btn {
    color: var(--black-color);
    font-weight: 600;
    font-family: var(--heading);
  }

  .arck-blog-item .inner-text .read-more-btn i,
  .arck-blog-item-2 .inner-text .read-more-btn i,
  .arck-service-item-2 .inner-text .read-more-btn i {
    margin-left: 3px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-blog-item .inner-text .read-more-btn:hover,
  .arck-blog-item-2 .inner-text .read-more-btn:hover,
  .arck-service-item-2 .inner-text .read-more-btn:hover {
    color: var(--main-color);
  }

  .arck-blog-item .inner-text .read-more-btn:hover i,
  .arck-blog-item-2 .inner-text .read-more-btn:hover i,
  .arck-service-item-2 .inner-text .read-more-btn:hover i {
    margin-left: 8px;
  }

  .arck-blog-section {
    padding: 120px 0px 95px;
  }

  .arck-blog-content {
    padding-top: 65px;
  }

  .arck-blog-details-main-content .blog-meta,
  .arck-blog-item .blog-meta,
  .arck-blog-item-2 .blog-meta {
    margin-bottom: 25px;
  }

  .arck-blog-details-main-content .blog-meta a,
  .arck-blog-item .blog-meta a,
  .arck-blog-item-2 .blog-meta a {
    margin-right: 15px;
  }

  .arck-blog-details-main-content .blog-meta a:last-child,
  .arck-blog-item .blog-meta a:last-child,
  .arck-blog-item-2 .blog-meta a:last-child {
    margin-right: 0;
  }

  .arck-blog-details-main-content .blog-meta .blog-cat,
  .arck-blog-item .blog-meta .blog-cat,
  .arck-blog-item-2 .blog-meta .blog-cat {
    color: #fff;
    font-size: 12px;
    padding: 7px 10px;
    background-color: var(--main-color);
  }

  .arck-blog-details-main-content .blog-meta .date,
  .arck-blog-item .blog-meta .date,
  .arck-blog-item-2 .blog-meta .date {
    color: #7a7a7a;
  }

  .arck-blog-item,
  .arck-blog-item-2 {
    margin-bottom: 32px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-blog-item .inner-img,
  .arck-blog-item-2 .inner-img {
    overflow: hidden;

  }

  .arck-blog-item .inner-img img,
  .arck-blog-item-2 .inner-img img {
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    width: 100%;
  }

  .arck-blog-item .inner-text,
  .arck-blog-item-2 .inner-text {
    padding: 45px 15px 20px;
  }

  .arck-blog-item .inner-text h3,
  .arck-blog-item-2 .inner-text h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.333;
    padding-bottom: 15px;
    color: var(--black-color);
  }

  .arck-blog-item .inner-text h3:hover,
  .arck-blog-item-2 .inner-text h3:hover {
    color: var(--main-color);
  }

  .arck-blog-item .inner-text p,
  .arck-blog-item-2 .inner-text p {
    line-height: 1.875;
    padding-bottom: 25px;
  }

  .arck-blog-item:hover .inner-img img,
  .arck-blog-item-2:hover .inner-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .arck-blog-item-2 .inner-text {
    padding-left: 30px;
  }

  .arck-sponsor-section {
    overflow: hidden;
    padding: 105px 0px;
    background-color: #f4f4f4;
  }

  .arck-sponsor-slider .slick-initialized .slick-slide,
  .arck-sponsor-slider .arck-sponsor-slider-item {
    text-align: center;
  }

  .arck-sponsor-slider .slick-slide img {
    display: inline-block;
  }

  .arck-blog-feed-content .arck-blog-item-2 {
    max-width: 770px;
    margin-bottom: 40px;
  }

  .arck-blog-feed-content .arck-blog-item-2 .inner-text {
    padding: 45px 40px;
  }

  .arck-blog-feed-content .arck-blog-item-2 .inner-text h3 {
    font-size: 30px;
  }

  .arck-side-bar-widget {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 30px 30px 40px;
    -webkit-box-shadow: 0px 5px 50px 0px rgba(110, 110, 110, 0.1);
    box-shadow: 0px 5px 50px 0px rgba(110, 110, 110, 0.1);
  }

  .arck-side-bar-widget .widget-title {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 25px;
  }

  .arck-side-bar-widget .search-widget form {
    position: relative;
  }

  .arck-side-bar-widget .search-widget input {
    width: 100%;
    height: 50px;
    padding: 15px;
    border: 1px solid #e7e7e7;
  }

  .arck-side-bar-widget .search-widget button {
    top: 15px;
    padding: 0;
    right: 15px;
    border: none;
    position: absolute;
    background-color: transparent;
  }

  .arck-side-bar-widget .category-widget li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 15px;
  }

  .arck-side-bar-widget .category-widget li a {
    width: 100%;
  }

  .arck-side-bar-widget .category-widget li a span {
    float: right;
    padding: 0px 10px;
    position: relative;
  }

  .arck-side-bar-widget .category-widget li a span:before,
  .arck-side-bar-widget .category-widget li a span:after {
    top: 15px;
    left: 0;
    width: 5px;
    height: 1px;
    content: "";
    position: absolute;
    background-color: var(--black-color);
  }

  .arck-side-bar-widget .category-widget li a span:after {
    right: 0;
    left: auto;
  }

  .arck-side-bar-widget .category-widget li a:hover {
    color: var(--main-color);
  }

  .arck-side-bar-widget .category-widget li:before {
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    border-radius: 100%;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    background-color: var(--black-color);
  }

  .arck-side-bar-widget .category-widget li:last-child {
    margin-bottom: 0;
  }

  .arck-side-bar-widget .category-widget li:hover:before {
    background-color: var(--main-color);
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text {
    padding-bottom: 25px;
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text:last-child {
    padding-bottom: 0;
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text .recent-blog-img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 15px;
    border-radius: 5px;
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text .recent-blog-text {
    width: 190px;
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text .recent-blog-text h3 {
    color: var(--black-color);
    font-size: 16px;
    line-height: 1.375;
    font-weight: 600;
    padding-bottom: 8px;
  }

  .arck-side-bar-widget .recent-post-widget .recent-blog-img-text .recent-blog-text span {
    font-size: 14px;
  }

  .arck-side-bar-widget .popular-tag-widget a {
    font-size: 13px;
    padding: 7px 20px;
    margin-right: 5px;
    margin-bottom: 8px;
    display: inline-block;
    background-color: #f4f4f4;
  }

  .arck-side-bar-widget .popular-tag-widget a:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  .arck-side-bar-widget .newslatter-widget input {
    width: 100%;
    height: 50px;
    border: none;
    padding-left: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #e9f6f6;
  }

  .arck-side-bar-widget .newslatter-widget button {
    color: #fff;
    height: 50px;
    border: none;
    width: 170px;
    border-radius: 5px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color);
  }

  .arck-side-bar-widget .newslatter-widget button:hover {
    background-color: #000e58;
  }

  .arck-side-bar-widget .brochure-widget h3 {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 600;
    padding: 30px 0px 15px;
  }

  .arck-blog-details-content .arck-project-next-prev-btn {
    margin-top: 50px;
  }

  .arck-blog-details-content .arck-contact-page-form {
    padding-left: 0;
  }

  .arck-blog-details-content .arck-appointment-form-wrap {
    max-width: 100%;
  }

  .arck-blog-details-content .arck-contact-page-form .arck-appointment-form-wrap form input,
  .arck-blog-details-content .arck-contact-page-form .arck-appointment-form-wrap form textarea {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e7e7e7;
  }

  .arck-blog-details-content .arck-contact-page-form .arck-appointment-form-wrap form textarea {
    height: 170px;
  }

  .arck-blog-details-main-content {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap {
    padding: 50px 40px 20px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap h2,
  .arck-blog-details-main-content .arck-blog-details-text-wrap h3 {
    color: var(--black-color);
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 30px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap h3 {
    font-size: 24px;
    padding-bottom: 20px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap p {
    line-height: 1.875;
    padding-bottom: 30px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap blockquote {
    font-size: 20px;
    line-height: 1.7;
    padding: 10px 55px;
    margin-bottom: 30px;
    border-left: 5px solid var(--main-color);
    font-family: var(--heading);
    color: var(--black-color);
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap ul {
    margin-bottom: 30px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap li {
    margin-bottom: 10px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap .arck-blog-details-img-wrap {
    margin-bottom: 30px;
  }

  .arck-blog-details-main-content .arck-blog-details-text-wrap .arck-blog-details-img-wrap-2 {
    margin-bottom: 50px;
  }

  .arck-blog-details-main-content .arck-blog-share-tag {
    border-top: 1px solid #ebebeb;
    padding: 20px 40px 30px;
  }

  .arck-blog-details-main-content .arck-blog-share-tag .blog-share span {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
  }

  .arck-blog-details-main-content .arck-blog-share-tag .blog-share a {
    margin-right: 10px;
    color: var(--black-color);
  }

  .arck-blog-details-main-content .arck-blog-share-tag .blog-hash-tag a {
    color: #101010;
    margin-left: 20px;
  }

  .arck-blog-author {
    margin-top: 60px;
    padding: 45px 30px;
    margin-bottom: 70px;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 50px 0px rgba(110, 110, 110, 0.1);
    box-shadow: 0px 5px 50px 0px rgba(110, 110, 110, 0.1);
  }

  .arck-blog-author .inner-img {
    width: 114px;
    height: 114px;
    overflow: hidden;
    margin-right: 30px;
    border-radius: 100%;
  }

  .arck-blog-author .inner-text {
    max-width: 510px;
  }

  .arck-blog-author .inner-text h4 {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  .arck-blog-author .inner-text p {
    padding-bottom: 15px;
  }

  .arck-blog-author .inner-text .inner-social a {
    margin-right: 25px;
  }

  .arck-blog-author .inner-text .inner-social a:hover {
    color: var(--main-color);
  }

  .blog_comment_box h3 {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 35px;
  }

  .blog_comment_box .comment_itemlist .comment_element {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebebeb;
  }

  .blog_comment_box .comment_itemlist .comment_img {
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin-right: 25px;
    border-radius: 100%;
  }

  .blog_comment_box .comment_itemlist .comment_content {
    max-width: 590px;
  }

  .blog_comment_box .comment_itemlist .comment_content h4 {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .blog_comment_box .comment_itemlist .comment_content p {
    padding: 10px 0px 15px;
  }

  .blog_comment_box .comment_itemlist .reply-btn {
    top: 0;
    right: 0;
    font-size: 15px;
    color: var(--main-color);
  }

  .comment_box_area {
    margin-bottom: 40px;
  }

  .comment_formfield .clenix-contact-form {
    margin-top: 10px;
  }

  /*---------------------------------------------------- */
  /*Before After area*/
  /*----------------------------------------------------*/
  .arck-before-after-section {
    padding: 125px 0px;
  }

  .arck-before-after-section:before,
  .arck-before-after-section:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    content: "";
    height: 605px;
    position: absolute;
    background: url("/assets/img/bg/ba-bg.jpg");
  }

  .arck-before-after-section:after {
    background: rgba(0, 0, 0, 0.7);
  }

  .arck-before-after-section .arck-section-title-2 {
    max-width: 480px;
  }

  .arck-before-after-section .arck-section-title-2 h2 {
    color: var(--white-color);
  }

  .arck-before-item {
    margin-right: 10px;
  }

  .arck-before-after-content {
    padding-top: 45px;
  }

  .arck-before-after-content .twentytwenty-handle {
    background-color: var(--main-color);
    border: none;
  }

  .arck-before-after-content .twentytwenty-after-label:before {
    content: "AFTER DESIGN";
  }

  .arck-before-after-content .twentytwenty-before-label:before {
    content: "BEFORE DESIGN";
  }

  .arck-before-after-content .twentytwenty-before-label:before,
  .arck-before-after-content .twentytwenty-after-label:before {
    top: auto;
    bottom: 30px;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 15px;
    line-height: 30px;
    color: var(--white-color);
    background-color: var(--main-color);
  }

  .arck-before-after-content .twentytwenty-horizontal .twentytwenty-before-label:before {
    left: 30px;
  }

  .arck-before-after-content .twentytwenty-horizontal .twentytwenty-after-label:before {
    right: 30px;
  }

  .arck-before-after-content .twentytwenty-overlay:hover {
    background-color: transparent;
  }

  .arck-before-after-content .twentytwenty-before-label,
  .arck-before-after-content .twentytwenty-after-label {
    opacity: 1;
  }

  /*---------------------------------------------------- */
  /*Skill area*/
  /*----------------------------------------------------*/
  .arck-working-skill-section {
    padding: 130px 0px;
  }

  .arck-working-skill-section .arck-section-title-2 h2 {
    padding-bottom: 20px;
  }

  .arck-working-skill-section .arck-section-title {
    padding-bottom: 20px;
  }

  .arck-working-skill-text-wrap {
    max-width: 540px;
    padding-top: 50px;
  }

  .arck-skill-progress-bar {
    padding-top: 20px;
  }

  .arck-skill-progress-bar .skill-set-percent {
    margin-bottom: 32px;
  }

  .arck-skill-progress-bar .skill-set-percent h4 {
    color: var(--black-color);
    font-size: 16px;
    padding-bottom: 15px;
    text-transform: uppercase;
  }

  .arck-skill-progress-bar .skill-set-percent .progress {
    height: 10px;
    border-radius: 0;
    position: relative;
    overflow: visible;
    border: 1px solid var(--black-color);
    background-color: transparent;
  }

  .arck-skill-progress-bar .skill-set-percent .progress span {
    right: 0;
    top: -25px;
    font-weight: 600;
    position: absolute;
    font-family: var(--heading);
  }

  .arck-skill-progress-bar .skill-set-percent .progress-bar {
    width: 0;
    left: 2px;
    top: 2px;
    float: left;
    height: 4px;
    position: relative;
    border-radius: 0;
    -webkit-transition: 1s all ease-in-out;
    transition: 1s all ease-in-out;
    background-color: var(--black-color);
  }

  /*---------------------------------------------------- */
  /*Price area*/
  /*----------------------------------------------------*/
  .arck-pricing-section {
    padding: 80px 0px;
  }

  .arck-pricing-section .arck-section-title-2 {
    max-width: 570px;
  }

  .arck-pricing-content {
    padding-top: 30px;
  }

  .arck-pricing-item {
    padding: 50px 30px 35px;
    background-color: var(--white-color);
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.05);
    box-shadow: 0px 0px 25px 0px rgba(72, 72, 72, 0.05);
  }

  .arck-pricing-item:hover {
    background-color: #efefef;
  }

  .arck-pricing-item .inner-title {
    padding-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
  }

  .arck-pricing-item .inner-title h3 {
    font-size: 26px;
    font-weight: 600;
    padding-bottom: 10px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    color: var(--black-color);
  }

  .arck-pricing-item .inner-title .inner-price h4 {
    font-size: 60px;
    font-weight: 600;
    padding-bottom: 10px;
    color: var(--main-color);
  }

  .arck-pricing-item .inner-title .inner-price h4 sup {
    font-size: 24px;
  }

  .arck-pricing-item .inner-title .inner-price span {
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: var(--black-color);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-pricing-item .inner-feature-list {
    margin: 35px 0px 40px;
  }

  .arck-pricing-item .inner-feature-list li {
    margin-bottom: 20px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-pricing-item .inner-feature-list li span {
    font-size: 17px;
    font-weight: 700;
    margin-right: 5px;
    color: var(--black-color);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .arck-pricing-item.active {
    background-color: var(--black-color);
  }

  .arck-pricing-item.active .inner-title {
    border-bottom: 1px solid #363636;
  }

  .arck-pricing-item.active .inner-title h3 {
    color: var(--white-color);
  }

  .arck-pricing-item.active .inner-price span {
    color: var(--white-color);
  }

  .arck-pricing-item.active .inner-feature-list li {
    color: var(--white-color);
  }

  .arck-pricing-item.active .inner-feature-list li span {
    color: var(--white-color);
  }

  .arck-pricing-item.active .arck-btn-2 a {
    color: var(--black-color);
    background-color: var(--white-color);
  }

  /*---------------------------------------------------- */
  /*Breadcrumb area*/
  /*----------------------------------------------------*/
  .arck-breadcrumb-section .background_overlay {
    opacity: 0.7;
    background-color: #030303;
  }

  .arck-breadcrumb-section .acrk-img-shape2 {
    left: 0;
    bottom: 0;
  }

  .arck-breadcrumb-section .acrk-img-shape1 {
    top: auto;
    right: 0;
    bottom: 0;
  }

  .arck-breadcrumb-content {
    padding: 310px 0px 235px;
  }

  .arck-breadcrumb-content h1 {
    font-size: 60px;
    font-weight: 600;
    padding-bottom: 28px;
    color: var(--white-color);
  }

  .arck-breadcrumb-content li {
    color: var(--white-color);
    font-weight: 600;
    margin: 0px 10px;
    position: relative;
    text-transform: uppercase;
    font-family: var(--heading);
  }

  .arck-breadcrumb-content li:before {
    top: 0;
    right: -18px;
    color: #fff;
    content: "//";
    position: absolute;
  }

  .arck-breadcrumb-content li:last-child:before {
    display: none;
  }

  /*---------------------------------------------------- */
  /*CTA area*/
  /*----------------------------------------------------*/
  .arck-cta-section {
    background-attachment: fixed;
    background-image: url("../img/slider-2/s2.png");
  }

  .arck-cta-section .background_overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .arck-cta-content {
    margin: 0 auto;
    max-width: 770px;
    padding: 120px 0px 140px;
  }

  .arck-cta-content h2 {
    font-size: 48px;
    font-weight: 600;
    color: var(--white-color);
  }

  .arck-cta-content .arck-cta-button-group {
    margin-top: 50px;
  }

  .arck-cta-content .arck-btn {
    margin-right: 40px;
  }

  .arck-cta-content .arck-btn a:before {
    border: 1px solid var(--white-color);
  }

  .arck-cta-content .arck-cta-number i {
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 25px;
    line-height: 60px;
    margin-right: 20px;
    border-radius: 100%;
    background-color: var(--main-color);
  }

  .arck-cta-content .arck-cta-number a {
    color: var(--white-color);
    font-weight: 600;
    font-size: 30px;
    font-family: var(--heading);
  }

  /*---------------------------------------------------- */
  /*Faq area*/
  /*----------------------------------------------------*/
  .arck-faq-video-section {
    padding: 80px 0px;
  }

  .arck-faq-content {
    max-width: 540px;
    padding-top: 35px;
  }

  .arck-faq-accordion {
    margin-top: 10px;
  }

  .arck-faq-accordion .accordion-header button {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 600;
    padding: 20px 30px;
  }

  .arck-faq-accordion .accordion-button:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .arck-faq-accordion .accordion-item {
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-faq-accordion .accordion-body {
    color: #000;
    background-color: #fff;
    padding: 20px 30px 30px;
  }

  .arck-faq-accordion .accordion-button:not(.collapsed) {
    color: #000;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color:#fff;
  }

  .arck-faq-accordion .accordion-button::after {
    right: 25px;
    height: auto;
    width: auto;
    font-size: 15px;
    content: "\f078";
    font-weight: 900;
    position: absolute;
    background-image: none;
    font-family: "Font Awesome 5 Pro";
  }

  .arck-faq-accordion .accordion-button:not(.collapsed)::after {
    /* content: "\f068";  */
  }

  .arck-faq-feed-section {
    padding: 125px 0px;
    background-color: #f4f4f4;
  }

  .arck-faq-feed-content {
    padding-top: 60px;
  }

  .arck-faq-feed-content .faq-feed-item {
    margin-bottom: 40px;
  }

  .arck-faq-feed-content .faq-feed-item h3 {
    color: var(--black-color);
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  /*---------------------------------------------------- */
  /*Shop area*/
  /*----------------------------------------------------*/
  .arck-shop-product-item {
    margin: 0 auto;
    max-width: 370px;
    margin-bottom: 30px;
  }

  .arck-shop-product-item .inner-img {
    margin-bottom: 25px;
  }

  .arck-shop-product-item .inner-text h3 {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .arck-shop-product-item .inner-text span {
    font-weight: 600;
    font-family: var(--heading);
    color: var(--main-color);
  }

  .arck-shop-product-item .hover-item {
    left: 0;
    right: 0;
    opacity: 0;
    bottom: 15px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .arck-shop-product-item .hover-item a {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--main-color);
    border-radius: 100%;
    margin: 0px 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .arck-shop-product-item .hover-item a:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  .arck-shop-product-item:hover .hover-item {
    opacity: 1;
    bottom: 50px;
  }

  .shop-details-text-wrap {
    padding-top: 15px;
    padding-left: 30px;
  }

  .shop-details-text-wrap h3 {
    color: var(--black-color);
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 12px;
  }

  .shop-details-text-wrap .arck-price {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--heading);
    color: var(--main-color);
    margin-bottom: 28px;
  }

  .shop-details-text-wrap .arck-code-category {
    margin-bottom: 30px;
  }

  .shop-details-text-wrap .arck-code-category li {
    margin-right: 30px;
  }

  .shop-details-text-wrap .arck-code-category li:last-child {
    margin-right: 0;
  }

  .shop-details-text-wrap .arck-code-category li span {
    color: var(--main-color);
  }

  .shop-details-text-wrap .item-desc-text {
    padding-bottom: 40px;
  }

  .shop-details-text-wrap .quantity-btn-area p {
    font-weight: 500;
    margin-right: 15px;
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field {
    margin-right: 30px;
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field input {
    width: 90px;
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-next,
  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-prev {
    top: 50%;
    cursor: pointer;
    font-size: 20px;
    margin: 0px 5px;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-next:hover,
  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-prev:hover {
    color: var(--main-color);
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-next {
    right: 5px;
  }

  .shop-details-text-wrap .quantity-btn-area .quantity-field .custom-prev {
    left: 8px;
  }

  .shop-details-text-wrap .quantity-btn-area .add-cart-btn a {
    color: #fff;
    height: 55px;
    width: 170px;
    font-weight: 500;
    background-color: var(--main-color);
  }

  .arck-shop-details-content {
    margin-bottom: 70px;
  }

  .shop-details-text-wrap .product-share {
    margin-top: 48px;
  }

  .shop-details-text-wrap .product-share a {
    color: #fff;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .shop-details-text-wrap .product-share a.fb-bg {
    background-color: #3b579d;
  }

  .shop-details-text-wrap .product-share a.tw-bg {
    background-color: #1da1f2;
  }

  .shop-details-text-wrap .product-share a.yt-bg {
    background-color: #ea4335;
  }

  .shop-details-text-wrap .product-share a.bh-bg {
    background-color: #0177b5;
  }

  .arck-shop-details-review-content .shop-review-tab-btn {
    margin-bottom: 40px;
  }

  .arck-shop-details-review-content .shop-review-tab-btn .nav-tabs {
    border-bottom: none;
  }

  .arck-shop-details-review-content .shop-review-tab-btn .nav-tabs .nav-link {
    border: none;
  }

  .arck-shop-details-review-content .shop-review-tab-btn li {
    color: #161616;
    margin-right: 15px;
    font-family: var(--heading);
    background-color: #f4f4f4;
  }

  .arck-shop-details-review-content .shop-review-tab-btn .nav-tabs .nav-link {
    font-size: 18px;
    border-radius: 0;
    padding: 8px 30px;
  }

  .arck-shop-details-review-content .shop-review-tab-btn .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--main-color);
  }

  .arck-shop-details-review-content .review-comment-area {
    max-width: 800px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-inner {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-pic {
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 100%;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text {
    overflow: hidden;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text h4 {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text span {
    display: block;
    padding-bottom: 15px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text .buyer-review-rate {
    margin-top: 5px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text .buyer-review-rate a {
    font-size: 14px;
    color: var(--main-color);
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text .buyer-review-date {
    color: rgba(105, 105, 105, 0.6);
    font-size: 14px;
    padding-left: 30px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-text .buyer-review-date:before {
    top: 3px;
    left: 10px;
    width: 2px;
    content: "";
    height: 15px;
    position: absolute;
    background-color: rgba(105, 105, 105, 0.6);
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option {
    margin: 4px 0px 20px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option li {
    width: 16px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option .customer-rate {
    display: none;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option .rate-value {
    position: relative;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option .rate-value:before {
    left: 0;
    top: -10px;
    content: "\f005";
    cursor: pointer;
    position: absolute;
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box .customer-rate-option .customer-rate:checked~.rate-value:before {
    content: "\f005";
    color: var(--main-color);
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box h4 {
    font-size: 18px;
    padding-right: 40px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box textarea,
  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box input {
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    padding-left: 40px;
    border: 1px solid #e5e5e5;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box textarea {
    height: 200px;
    padding-top: 30px;
    margin-bottom: 15px;
  }

  .arck-shop-details-review-content .review-comment-area .buyer-review-comment-box button {
    color: #fff;
    border: none;
    font-weight: 500;
    padding: 15px 50px;
    font-family: var(--heading);
    text-transform: uppercase;
    font-weight: 700;
    background-color: var(--main-color);
  }

  .arck-shop-feed-content {
    margin: 0px -15px;
  }

  .arck-more-product {
    padding-top: 40px;
  }

  .arck-more-product .arck-section-title {
    padding-bottom: 30px;
  }

  .arck-more-product .arck-section-title h2 {
    font-size: 30px;
  }

  /*---------------------------------------------------- */
  /*Contact area*/
  /*----------------------------------------------------*/
  .arck-contact-page-cta .arck-video-cta-wrap {
    background-color: transparent;
  }

  .arck-contact-page-cta .arck-video-cta-wrap .video-cta-item {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }

  .arck-contact-page-cta .arck-video-cta-wrap .video-cta-item:hover {
    background-color: transparent;
  }

  .arck-contact-page-cta .arck-video-cta-wrap .video-cta-item .inner-text {
    width: auto;
    padding: 0;
  }

  .arck-contact-page-cta .contact-cta-inner-text {
    top: 50px;
    right: 0;
    padding: 45px 40px 15px;
    background-image: url('../img/about/cn-bg.jpg');
  }

  .arck-contact-page-cta .contact-cta-inner-text h3 {
    color: var(--white-color);
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 45px;
  }

  .arck-contact-page-form {
    padding-left: 30px;
  }

  .arck-contact-page-form .arck-appointment-form-wrap .arck-section-title h2 {
    color: var(--black-color);
  }

  .arck-contact-page-form .arck-section-title {
    padding-bottom: 45px;
  }

  .arck-contact-page-form .arck-appointment-form-wrap form input,
  .arck-contact-page-form .arck-appointment-form-wrap form textarea,
  .arck-contact-page-form .arck-appointment-form-wrap form p-dropdown {
    background-color: var(--white-color);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }

  .arck-contact-page-form .arck-appointment-form-wrap form button {
    border: none;
    background-color: var(--black-color);
  }

  .arck-contact-page-form .arck-appointment-form-wrap form button:before {
    border: 1px solid var(--main-color);
  }

  .arck-contact-page-form .arck-appointment-form-wrap form button:after {
    background-color: var(--black-color);
  }

  .arck_map {
    height: 475px;
  }

  .arck_map iframe {
    width: 100%;
  }

  /*---------------------------------------------------- */
  /*Footer area*/
  /*----------------------------------------------------*/
  .arck-footer-widget-wrapper {
    padding: 100px 0px;
  }

  .arck-footer-widget .widget-title {
    color: var(--white-color);
    font-size: 24px;
    font-weight: 600;
    padding-bottom: var(--padding-bottom-50);
  }

  .arck-footer-widget .contact-cta-widget li {
    color: #fff;
    margin-bottom: 15px;
  }

  .arck-footer-widget .contact-cta-widget li i {
    top: 5px;
    float: left;
    font-size: 20px;
    padding-top: 3px;
    margin-right: 12px;
    position: relative;
    color: var(--main-color);
  }

  .arck-footer-widget .contact-cta-widget li span {
    display: block;
    overflow: hidden;
    max-width: 225px;
  }

  .arck-footer-widget .menu-widget li {
    color: #fff;
    margin-bottom: 10px;
  }

  .arck-footer-widget .menu-widget li a {
    padding-left: 25px;
    position: relative;
  }

  .arck-footer-widget .menu-widget li a:hover {
    color: var(--main-color);
    margin-left: 8px;
  }

  .arck-footer-widget .menu-widget li a:before {
    left: 0;
    top: 5px;
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: var(--main-color);
  }

  .arck-footer-widget .blog-img-content {
    max-width: 300px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #4a4a4a;
  }

  .arck-footer-widget .blog-img-content .blog-img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .arck-footer-widget .blog-img-content .blog-text {
    width: 200px;
  }

  .arck-footer-widget .blog-img-content .blog-text h3 {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    color: var(--white-color);
  }

  .arck-footer-widget .blog-img-content .blog-text .blog-meta {
    color: #cccccc;
    font-size: 14px;
  }

  .arck-footer-widget .blog-img-content:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .arck-footer-widget .newslatter-widget .footer-social {
    margin-top: 30px;
  }

  .arck-footer-widget .newslatter-widget .footer-social a {
    color: #fff;
    margin-right: 15px;
  }

  .arck-footer-widget .newslatter-widget .footer-social a:hover {
    color: var(--main-color);
  }

  .arck-footer-widget .newsleter-form input {
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid #fff;
    background-color: transparent;
  }

  .arck-footer-widget .newsleter-form input::-webkit-input-placeholder {
    color: #fff;
  }

  .arck-footer-widget .newsleter-form input::-moz-placeholder {
    color: #fff;
  }

  .arck-footer-widget .newsleter-form input:-ms-input-placeholder {
    color: #fff;
  }

  .arck-footer-widget .newsleter-form input::-ms-input-placeholder {
    color: #fff;
  }

  .arck-footer-widget .newsleter-form input::placeholder {
    color: #fff;
  }

  .arck-footer-widget .newsleter-form button {
    width: 100%;
    color: #fff;
    height: 50px;
    border: none;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color);
  }

  .arck-footer-widget .newsleter-form button:hover {
    color: var(--main-color);
    background-color: #fff;
  }

  .arck-footer-copyright {
    color: #fff;
    padding: 15px;
    background-color: #2d2d2d;
  }

  /*---------------------------------------------------- */
  /*New Home area*/
  /*----------------------------------------------------*/
  /*Global Style*/
  /*----------------------------------------------------*/
  .feah-section-title {
    padding-bottom: 50px;
  }

  .feah-section-title .title-serial {
    line-height: 1;
    font-size: 80px;
    font-weight: 700;
    padding-bottom: 15px;
    display: inline-block;
    color: var(--main-color-2);
    font-family: var(--heading-2);
  }

  .feah-section-title h2 {
    color: #000000;
    font-size: 38px;
    font-weight: 500;
    line-height: 50px;
  }

  .feah-section-title h2 span {
    font-weight: 400;
    position: relative;
    display: inline-block;
    color: var(--main-color-2);
  }

  .feah-section-title h2 span:before {
    left: 0;
    bottom: 3px;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: var(--main-color-2);
  }

  /*Header Section 3*/
  /*----------------------------------------------------*/
  .feah-header-section {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding-top: 20px;
    position: absolute;
  }

  .feah-header-section .container {
    max-width: 1505px;
  }

  .feah-header-section .navbar-nav {
    display: inherit;
  }

  .feah-header-section .dropdown {
    position: relative;
  }

  .feah-header-section .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    clip: inherit;
    -webkit-transform: scaleY(1);
    -khtml-transform: scaleY(1);
    transform: scaleY(1);
  }

  .feah-header-section .dropdown-menu {
    left: 0;
    top: 60px;
    z-index: 100;
    margin: 0px;
    padding: 20px 0;
    height: auto;
    min-width: 250px;
    display: block;
    border: none;
    background-color: #fff !important;
    border-radius: 0;
    position: absolute;
    opacity: 0;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: 0 0 0;
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    -o-transform-origin: 0 0 0;
    text-align: left;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .feah-header-section .dropdown-menu .dropdown-menu {
    top: 30px;
    left: 250px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 0 !important;
  }

  .feah-header-section .dropdown-menu .dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #1a2428;
  }

  .feah-header-section .dropdown-menu .dropdown-menu a:before {
    display: none;
  }

  .feah-header-section .dropdown-menu li {
    display: block;
    margin: 0 !important;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
  }

  .feah-header-section .dropdown-menu li:last-child {
    border-bottom: none;
  }

  .feah-header-section .dropdown-menu li:after {
    display: none;
  }

  .feah-header-section .dropdown-menu li:hover .dropdown-menu {
    top: 0;
    opacity: 1 !important;
  }

  .feah-header-section .dropdown-menu a {
    width: 100%;
    display: block;
    position: relative;
    padding: 10px 30px !important;
    color: #1a2428 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }

  .feah-header-section .dropdown-menu a:before {
    display: none;
  }

  .feah-header-section .dropdown-menu a:hover {
    color: var(--main-color-2) !important;
  }

  .feah-header-section .main-navigation li {
    margin: 0px 20px;
    position: relative;
  }

  .feah-header-section .main-navigation li:after {
    top: 15px;
    width: 5px;
    height: 5px;
    content: "";
    right: -10px;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    border-radius: 100%;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color-2);
  }

  .feah-header-section .main-navigation li a {
    font-weight: 600;
    color: var(--black-color);
    padding-bottom: var(--padding-bottom-35);
    font-size: 16px;
  }

  .feah-header-section .main-navigation li:hover:after {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .feah-header-section .main-navigation li:hover a {
    color: var(--main-color-2);
  }

  .feah-header-section .header-top-content {
    margin-bottom: 20px;
    align-items: center;
  }

  .feah-header-section .header-top-content .header-top-cta li {
    color: #fff;
    font-size: 15px;
    position: relative;
    margin-right: 30px;
  }

  .feah-header-section .header-top-content .header-top-cta li:before {
    top: 3px;
    width: 1px;
    content: "";
    right: -18px;
    height: 18px;
    position: absolute;
    background-color: rgba(229, 229, 229, 0.1882352941);
  }

  .feah-header-section .header-top-content .header-top-cta li:last-child:before {
    display: none;
  }

  .feah-header-section .header-top-content .header-top-cta li i {
    margin-right: 10px;
    color: #67a06e;
  }

  .brand-logo {
    top: 0;
    left: 0;
    right: 0;
    width: 122px;
    float: left;
    /*margin: 0 auto;
  position: absolute;*/
  }

  .feah-header-section .header-top-content .header-social {
    margin-left: 15px;
    position: relative;
  }

  .feah-header-section .header-top-content .header-social:before {
    top: 3px;
    width: 1px;
    content: "";
    left: -5px;
    height: 18px;
    position: absolute;
    background-color: rgba(229, 229, 229, 0.1882352941);
  }

  .feah-header-section .header-top-content .header-social a {
    color: #fff !important;
    margin-left: 15px;
  }

  .feah-header-section .header-top-content .header-social a:hover {
    color: var(--main-color-2);
  }

  .feah-header-section .header-top-content .header-language-select select {
    color: #fff;
    border: none;
    background-color: transparent;
  }

  .feah-header-section .header-top-content .header-language-select select option {
    color: var(--main-color-2);
  }

  .feah-header-section .header-main-menu-wrapper {
    z-index: 1;
    padding: 18px 15px;
    background-color: #fff;
  }

  .feah-header-section .header-main-menu-wrapper:before {
    left: 0;
    width: 0;
    top: 0px;
    height: 0;
    z-index: -1;
    content: "";
    position: absolute;
    border-right: 30px solid transparent;
    border-bottom: 92px solid var(--main-color-2);
  }

  .feah-header-section .feah-side-bar-button {
    cursor: pointer;
    padding-left: 20px;
    margin-right: 25px;
  }

  .feah-main-navigation-wrap {
    display: flex;
    align-items: baseline;
  }

  .feah-header-section .feah-side-bar-button span {
    height: 2px;
    display: block;
    margin: 5px 0px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: #000000;
  }

  .feah-header-section .feah-side-bar-button span:nth-child(1) {
    width: 14px;
  }

  .feah-header-section .feah-side-bar-button span:nth-child(2) {
    width: 22px;
  }

  .feah-header-section .feah-side-bar-button span:nth-child(3) {
    width: 18px;
  }

  .feah-header-section .feah-side-bar-button:hover span:nth-child(1),
  .feah-header-section .feah-side-bar-button:hover span:nth-child(2),
  .feah-header-section .feah-side-bar-button:hover span:nth-child(3) {
    width: 22px;
  }

  .feah-header-section .header-cta-number {
    line-height: 1;
  }

  .feah-header-section .header-cta-number .header-cta-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 100%;
    background-color: #F5F5F5;
  }

  .feah-header-section .header-cta-number .header-cta-icon i {
    color: var(--black-color);
  }

  .feah-header-section .header-cta-number .ar-title {
    font-size: 13px;
    font-weight: 500;
  }

  .feah-header-section .header-cta-number .ar-value {
    display: block;
    color: #76a77d;
    font-size: 14px;
    font-family: var(--heading-2);
  }

  .feah-header-section .header-cta-btn {
    margin-left: 30px;
  }

  .feah-header-section .header-cta-btn a {
    color: #fff;
    width: 155px;
    height: 55px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--main-color-2);
  }

  .feah-header-section .header-cta-btn a i {
    margin-left: 8px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-header-section .header-cta-btn a:hover {
    background-color: #000;
  }

  .feah-header-section .header-cta-btn a:hover i {
    margin-left: 10px;
  }

  .feah-header-section.sticky_on {
    top: 0;
    padding: 0;
    width: 100%;
    z-index: 20;
    position: fixed;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    background-color: var(--white-color);
    -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  }

  .feah-header-section.sticky_on .header-top-content {
    display: none !important;
  }

  .feah-header-section.sticky_on .header-main-menu-wrapper:before {
    display: none;
  }

  .feah-header-section.sticky_on .feah-side-bar-button {
    padding-left: 0;
  }

  .feah-header-section.feah-header-style-two {
    padding-top: 0;
    background-color: #fff;
  }

  .feah-header-section.feah-header-style-two .header-main-menu-wrapper {
    padding: 20px 85px;
  }

  .feah-header-section.feah-header-style-two .header-main-menu-wrapper:before {
    display: none;
  }

  .feah-header-section.feah-header-style-two .feah-side-bar-button {
    padding-left: 0;
  }

  /*Slider Section*/
  /*---------------------------------------------------*/
  .feah-slider-section {
    padding: 340px 0px 250px;
  }

  .feah-slider-section .feah-slider-side1 {
    top: 90px;
    left: 30px;
    color: #fff;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .feah-slider-section .feah-slider-side2 {
    left: 30px;
    bottom: 80px;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .feah-slider-section .feah-slider-side2 li {
    margin-bottom: 40px;
  }

  .feah-slider-section .feah-slider-side2 li a {
    color: #fff;
  }

  .feah-slider-section .feah-slider-side2 li a:hover {
    color: var(--main-color-2);
  }

  .feah-slider-counter {
    z-index: 1;
  }

  .feah-slider-counter .ar-slider-shape-img {
    left: 0px;
    top: -80px;
    z-index: -1;
    opacity: 0.2;
  }

  .feah-slider-counter .feah-slider-counter-text {
    max-width: 480px;
  }

  .feah-slider-counter .feah-slider-counter-text h2 {
    line-height: 1;
    font-size: 200px;
    font-weight: 700;
    color: var(--main-color-2);
  }

  .feah-slider-counter .feah-slider-counter-text p {
    color: #fff;
    font-size: 18px;
    font-family: var(--heading-2);
  }

  .feah-slider-content:before {
    left: 0;
    right: 0;
    top: -85px;
    width: 1px;
    content: "";
    height: 530px;
    margin: 0 auto;
    position: absolute;
    background-color: var(--main-color-2);
  }

.feah-slider-text {
/* padding-left: 80px; */
text-align: start;
}
.feah-slider-text .slider-slug {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 25px;
  margin-bottom: 25px;
  display: inline-block;
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
  background-color: var(--main-color-2);
}
.feah-slider-text h1 {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
  line-height: 55px;
  padding-bottom: 20px;
}
.feah-slider-text p {
  color: #fff;
  padding-bottom: 30px;
}

  .feah-video-play-btn .feah-slider-btn {
    margin-right: 25px;
  }

  .feah-video-play-btn .feah-slider-btn a {
    color: #fff;
    width: 165px;
    height: 55px;
    background-color: var(--main-color-2);
  }

  .feah-video-play-btn .feah-slider-btn a i {
    margin-left: 5px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-video-play-btn .feah-slider-btn a:hover {
    color: #000;
    background-color: #fff;
  }

  .feah-video-play-btn .feah-slider-btn a:hover i {
    margin-left: 10px;
  }

  .feah-video-play-btn .feah-video-play-btn a {
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px solid #fff;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .feah-slider-section-2 {
    padding-top: 95px;
    background-color: #f5f5f5;
  }

  .feah-slider-section-2 .feah-banner-img1 {
    left: 55px;
    top: 120px;
    z-index: 1;
  }

  .feah-slider-section-2 .feah-banner-img2 {
    top: 210px;
    left: 140px;
  }

  .feah-slider-section-2 .feah-banner-img3 {
    left: 75px;
    bottom: 230px;
  }

  .feah-slider-section-2 .feah-banner-img4 {
    left: 220px;
    bottom: 100px;
  }

  .feah-slider-section-2 .feah-banner-img5 {
    top: 210px;
    right: 160px;
    z-index: 1;
  }

  .feah-slider-section-2 .feah-banner-img6 {
    right: 60px;
    z-index: 1;
    bottom: 130px;
  }

  .feah-slider-section-2 .feah-banner-img7 {
    z-index: 0;
    right: 205px;
    bottom: 160px;
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 5000ms;
    animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .feah-slider-section-2 .feah-banner-counter {
    bottom: 255px;
    right: 275px;
  }

  .feah-slider-section-2 .feah-banner-counter h3 {
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: var(--main-color-2);
  }

  .feah-slider-section-2 .feah-banner-counter p {
    font-size: 18px;
    color: #000000;
    font-family: var(--heading-2);
  }

  .feah-slider-content-2 .feah-slider-text-2 {
    margin: 0 auto;
    max-width: 550px;
    padding: 250px 0 285px;
  }

  .feah-slider-content-2 .feah-slider-text-2 .slider-slug {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 30px;
    border-radius: 20px;
    display: inline-block;
    background-color: #000000;
  }

  .feah-slider-content-2 .feah-slider-text-2 h1 {
    color: #000000;
    font-size: 48px;
    font-weight: 500;
    padding: 20px 0px 30px;
  }

  .feah-slider-content-2 .feah-slider-text-2 h1 span {
    color: var(--main-color-2);
  }

  .feah-slider-content-2 .feah-slider-text-2 p {
    color: #000000;
    padding-bottom: 40px;
  }

  .feah-slider-content-2 .feah-slider-text-2 .feah-video-play-btn .feah-slider-btn a:hover {
    color: #fff;
    background-color: #000;
  }

  .feah-slider-content-2 .feah-slider-text-2 .feah-video-play-btn .feah-video-play-btn a {
    color: #000;
    border: 1px solid #000;
  }

  .slider-side-content {
    top: 0;
    left: 0;
    width: 85px;
    z-index: 12;
    height: 100%;
    background-color: #fff;
  }

  .slider-side-content .feah-slider-side1 {
    top: 100px;
    left: 25px;
    color: #000;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .slider-side-content .feah-slider-side2 {
    color: #000;
    left: 25px;
    bottom: 100px;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .slider-side-content .feah-slider-side2 li {
    margin-bottom: 20px;
  }

  .slider-side-content .feah-slider-side2 li a:hover {
    color: var(--main-color-2);
  }

  /*Feature Section*/
  /*----------------------------------------------------*/
  .feah-feature-section .feah-bg {
    left: 0;
    top: -150px;
  }

  .feah-feature-content {
    margin: 0 auto;
    max-width: 1920px;
  }

  .feah-feature-img_2 {
    z-index: 1;
    top: -85px;
    margin-bottom: -115px;
  }

  .feah-feature-img_2 img {
    filter: grayscale(100%) !important;
  }

  .feah-feature-img_2 img:hover {
    filter: grayscale(0%) !important;
  }

  .feah-feature-img_2 .feah-ft-shape {
    top: 182px;
    z-index: -1;
    left: -40px;
  }

  .feah-feature-img_2 .award-wining-text {
    top: 120px;
    right: -70px;
    color: #020202;
    padding: 5px 20px;
    background-color: #fff;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .feah-feature-img_2 .award-wining-text span {
    color: var(--main-color-2);
  }

  .feah-feature-img_2 .feah-experience-text {
    color: #fff;
    left: 15px;
    bottom: 35px;
    width: 130px;
    height: 120px;
    font-size: 50px;
    line-height: 1.1;
    font-weight: 500;
    padding-top: 15px;
    font-family: var(--heading-2);
    background-color: var(--main-color-2);
    filter: grayscale(0%) !important;
  }

  .feah-feature-img_2 .feah-experience-text sub {
    bottom: 0;
    line-height: 1;
    font-size: 23px;
    font-weight: 700;
  }

  .feah-faq-img-wrap {
    filter: grayscale(100%) !important;
  }

  .feah-faq-img-wrap:hover {
    filter: grayscale(0%) !important;
  }

  .feah-feature-img_2 .feah-experience-text p {
    margin: 0 auto;
    font-size: 14px;
    max-width: 100px;
    font-weight: 400;
  }

  .feah-feature-content-item_2 {
    padding-top: 60px;
    padding-left: 15px;
  }

  .feah-feature-item_2 .feah-feature-icon,
  .feah-service-section-2 .feah-feature-item .feah-feature-icon {
    margin-bottom: 20px;
    width: 108px;
    height: 120px;
    display: inline-block;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
    transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  }

  .feah-feature-item_2 .feah-feature-text h3,
  .feah-service-section-2 .feah-feature-item .feah-feature-text h3 {
    color: #020202;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
  }

  .feah-feature-item_2 .feah-feature-text p,
  .feah-service-section-2 .feah-feature-item .feah-feature-text p {
    line-height: 32px;
    padding-bottom: 8px;
  }

  .feah-feature-item_2 .feah-feature-text a,
  .feah-service-section-2 .feah-feature-item .feah-feature-text a {
    color: #020202;
    font-size: 500;
  }

  .feah-feature-item_2 .feah-feature-text a i,
  .feah-service-section-2 .feah-feature-item .feah-feature-text a i {
    margin-left: 5px;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease;
  }

  .feah-feature-item_2 .feah-feature-text a:hover,
  .feah-service-section-2 .feah-feature-item .feah-feature-text a:hover {
    color: var(--main-color-2);
  }

  .feah-feature-item_2 .feah-feature-text a:hover i,
  .feah-service-section-2 .feah-feature-item .feah-feature-text a:hover i {
    margin-left: 8px;
  }

  .feah-feature-item_2:hover .feah-feature-icon,
  .feah-service-section-2 .feah-feature-item:hover .feah-feature-icon {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }

  .feah-service-section-2 .feah-feature-item .feah-feature-text p {
    max-width: 240px;
  }

  .feah-feature-cta-text {
    color: #000000;
    font-size: 14px;
    padding: 3px 20px;
    margin-top: 30px;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.168627451);
  }

  .feah-feature-cta-text a {
    color: #498951;
  }

  .feah-produce-section {
    padding-top: 75px;
    overflow: hidden;
  }

  .feah-produce-title {
    padding-bottom: 40px;
  }

  .feah-produce-title h3 {
    color: #000000;
    font-size: 28px;
    font-weight: 700;
  }

  /*Faq Section*/
  /*----------------------------------------------------*/
  .feah-faq-section {
    z-index: 1;
    overflow: hidden;
    padding: 90px 0px 70px;
  }

  .feah-faq-section .feah-section-title {
    max-width: 730px;
  }

  .feah-faq-section .ar-bg {
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .feah-faq-content-wrapper .accordion-item {
    border: none;
    margin-bottom: 10px;
    border-bottom: 1px solid #D9D9D9;
  }

  .feah-faq-content-wrapper .accordion-item:first-of-type .accordion-button {
    border-radius: 0;
  }

  .feah-faq-content-wrapper .accordion-button:not(.collapsed) {
    background-color: transparent;
  }

  .feah-faq-content-wrapper .accordion-item:first-of-type {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #D9D9D9;
  }

  .feah-faq-content-wrapper .accordion-button {
    padding: 0;
    border: none;
    border: none;
    height: 50px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }

  .feah-faq-content-wrapper .accordion-button:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .feah-faq-content-wrapper .accordion-body {
    padding: 0;
    color: rgba(0, 0, 0, 0.5019607843);
    line-height: 42px;
  }

  .feah-faq-content-wrapper .accordion-button:not(.collapsed)::after,
  .feah-faq-content-wrapper .accordion-button::after {
    width: auto;
    height: auto;
    background-image: none;
    content: "\f067";
    font-weight: 900;
    font-family: "Font Awesome 5 Pro";
  }

  .feah-faq-content-wrapper .accordion-button:not(.collapsed)::after {
    content: "\f068";
  }

  .feah-faq-section-2 {
    z-index: 1;
    padding: 120px 0px 90px;
  }

  .feah-faq-section-2:before {
    top: 0;
    right: 0;
    z-index: -1;
    width: 53%;
    content: "";
    height: 100%;
    position: absolute;
    background-color: #f7f4f3;
  }

  .feah-faq-accordion-2 .feah-faq-content-wrapper .accordion-item:first-of-type,
  .feah-faq-accordion-2 .feah-faq-content-wrapper .accordion-item,
  .feah-faq-accordion-2 .accordion-item:last-of-type .accordion-button.collapsed,
  .feah-faq-accordion-2 .feah-faq-content-wrapper .accordion-item:first-of-type .accordion-button {
    border: none;
    background-color: #f7f4f3;
  }

  .feah-faq-accordion-2 .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .feah-faq-pointer-wrapper {
    top: 0;
    left: 0;
    width: 47%;
    height: 100%;
    background-color: #fcfcfc;
  }

  .feah-faq-pointer-wrapper .feah-faq-exp {
    top: 80px;
    left: 25px;
    width: 140px;
    height: 120px;
    padding-top: 25px;
    background-color: #000;
  }

  .feah-faq-pointer-wrapper .feah-faq-exp h3 {
    color: #fff;
    line-height: 0.75;
    font-size: 50px;
    font-weight: 700;
  }

  .feah-faq-pointer-wrapper .feah-faq-exp p {
    color: #fff;
    font-size: 12px;
  }

  .feah-point-area {
    top: 10%;
    right: 10%;
    position: absolute;
  }

  .feah-point-area .feah-pointer-indicatior {
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .feah-point-area .feah-pointer-indicatior:hover {
    color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: var(--main-color-2);
  }

  .feah-point-area .feah-pointer-text {
    top: -25px;
    opacity: 0;
    right: 40px;
    width: 240px;
    padding: 20px;
    position: absolute;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  }

  .feah-point-area .feah-pointer-text:before {
    width: 0;
    height: 0;
    top: 40px;
    content: "";
    right: -20px;
    position: absolute;
    border-left: 20px solid #d3d1d1;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .feah-point-area .feah-pointer-text h3 {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .feah-point-area .feah-pointer-text p {
    font-size: 12px;
    line-height: 19px;
  }

  .feah-point-area:hover .feah-pointer-text {
    opacity: 1;
    right: 80px;
  }

  .feah-point-area:nth-child(2) {
    top: 20%;
    right: 50%;
  }

  .feah-point-area:nth-child(3) {
    top: auto;
    right: 15%;
    bottom: 20%;
  }

  .feah-point-area:nth-child(4) {
    top: auto;
    right: 30%;
    bottom: 50%;
  }

  .feah-point-area:nth-child(5) {
    top: auto;
    left: 40%;
    right: auto;
    bottom: 30%;
  }

  /*Fun Fact Section*/
  /*----------------------------------------------------*/
  .feah-fun-fact-section {
    padding: 45px 0px;
    background-color: var(--main-color-2);
  }

  .feah-fun-fact-title h3 {
    color: #fff;
    font-size: 34px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  .feah-fun-fact-title p {
    color: #fff;
  }

  .feah-fun-fact-item h3 {
    opacity: 0.3;
    color: #FFFFFF;
    line-height: 1;
    font-size: 93px;
  }

  .feah-fun-fact-item p {
    color: #000000;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 5px;
  }

  /*Team Section*/
  /*----------------------------------------------------*/
  .feah-team-section {
    overflow: hidden;
  }

  .feah-team-section .container {
    max-width: 1500px;
  }

  .feah-team-content {
    padding: 100px 60px;
    background-color: #fbf8f6;
  }

  .feah-team-content .feah-section-title {
    margin: 0 auto;
    max-width: 560px;
  }

  .feah-team-slider-area {
    margin: 0px -15px;
  }

  .feah-team-item-wrap {
    padding: 0px 15px;
  }

  .feah-team-item {
    max-width: 322px;
  }

  .feah-team-item .team-text {
    left: 0;
    right: 0;
    width: 80%;
    opacity: 0;
    bottom: 0px;
    margin: 0 auto;
    padding: 20px 15px;
    position: absolute;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-team-item .team-text span {
    font-size: 13px;
    color: var(--main-color-2);
  }

  .feah-team-item .team-text h3 {
    color: #241616;
    font-size: 16px;
  }

  .feah-team-item .team-social {
    top: 80px;
    right: 30px;
    position: absolute;
  }

  .feah-team-item .team-social:before {
    top: -45px;
    left: -11px;
    width: 34px;
    height: 34px;
    color: #5D5D70;
    content: "\f067";
    font-weight: 900;
    line-height: 34px;
    text-align: center;
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    font-family: "Font Awesome 5 Pro";
  }

  .feah-team-item .team-social ul {
    opacity: 0;
    margin-top: 20px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-team-item .team-social li {
    margin-bottom: 5px;
  }

  .feah-team-item .team-social li a {
    color: #3E3E4F;
  }

  .feah-team-item .team-social li a:hover {
    color: var(--main-color-2);
  }

  .feah-team-item:hover .team-text {
    opacity: 1;
    bottom: 25px;
  }

  .feah-team-item:hover .team-social:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .feah-team-item:hover .team-social ul {
    opacity: 1;
    margin-top: 0;
  }

  .ar-team_left_arrow,
  .ar-team_right_arrow {
    top: 50%;
    border: none;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent;
  }

  .ar-team_left_arrow {
    left: -180px;
  }

  .ar-team_right_arrow {
    right: -180px;
  }

  /*Project Section*/
  /*----------------------------------------------------*/
  .feah-project-section {
    overflow: hidden;
    border-top: 1px solid #D7D8E3;
  }

  .feah-project-item {
    width: 25%;
    overflow: hidden;
    padding: 40px 50px 50px;
    border-right: 1px solid #D7D8E3;
  }

  .feah-project-item:last-child {
    border-right: none;
  }

  .feah-project-item .hover-item {
    top: 0;
    opacity: 0;
    left: -30px;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-project-item .hover-item .inner-text {
    left: 0;
    right: 0;
    width: 90%;
    opacity: 0;
    bottom: -40px;
    margin: 0 auto;
    padding: 28px 25px;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .feah-project-item .hover-item .inner-text .inner-serial {
    font-size: 40px;
    font-weight: 700;
    margin-left: 20px;
    color: var(--main-color-2);
    font-family: var(--heading-2);
  }

  .feah-project-item .hover-item .inner-title {
    margin-top: 15px;
  }

  .feah-project-item .hover-item .inner-title h3 {
    color: #000000;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
  }

  .feah-project-item .hover-item .inner-title a {
    color: #020202;
    font-size: 14px;
    font-weight: 500;
  }

  .feah-project-item .hover-item .inner-title a i {
    margin-left: 5px;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease;
  }

  .feah-project-item .hover-item .inner-title a:hover {
    color: var(--main-color-2);
  }

  .feah-project-item .hover-item .inner-title a:hover i {
    margin-left: 8px;
  }

  .feah-project-item .img-bg {
    left: 0;
    bottom: 0;
  }

  .feah-project-item .project-icon {
    margin-bottom: 130px;
  }

  .feah-project-item .project-text h3 {
    color: #000000;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    padding-bottom: 15px;
  }

  .feah-project-item .project-text p {
    padding-bottom: 20px;
  }

  .feah-project-item .project-text a {
    color: #020202;
    font-size: 14px;
    font-weight: 500;
  }

  .feah-project-item .project-text a i {
    margin-left: 5px;
  }

  .feah-project-item:hover .hover-item {
    left: 0;
    opacity: 1;
  }

  .feah-project-item:hover .hover-item .inner-text {
    opacity: 1;
    bottom: 25px;
  }

  /*Before After Section*/
  /*----------------------------------------------------*/
  .feah-before-after-section {
    z-index: 1;
    overflow: hidden;
    padding: 70px 0px 110px;
    background-color: #232A31;
  }

  .feah-before-after-section .feah-section-title {
    margin: 0 auto;
    max-width: 680px;
  }

  .feah-before-after-section .feah-section-title h2 {
    color: #fff;
  }

  .feah-before-after-section .feah-before-after-shape {
    top: 70px;
    left: 20px;
    z-index: -1;
    opacity: 0.2;
  }

  .feah-before-after-section .tab-content>.tab-pane {
    height: 0;
    display: block;
  }

  .feah-before-after-section .tab-content>.active {
    height: auto;
    display: block;
  }

  .feah-before-after-tab-btn {
    padding-left: 50px;
  }

  .feah-before-after-tab-btn:before {
    top: 0;
    left: 0;
    width: 4px;
    content: "";
    height: 100%;
    border-radius: 5px;
    position: absolute;
    background-color: #EAEAEA;
  }

  .feah-before-after-tab-btn .nav {
    display: inherit;
  }

  .feah-before-after-tab-btn .nav-link {
    padding: 0;
  }

  .feah-before-after-tab-btn .nav-pills .nav-link {
    color: #fff;
    border-radius: 0;
  }

  .feah-before-after-tab-btn .nav-pills .nav-link.active,
  .feah-before-after-tab-btn .nav-pills .show>.nav-link,
  .feah-before-after-tab-btn .nav-pills .nav-link {
    position: relative;
    background-color: transparent;
  }

  .feah-before-after-tab-btn .nav-pills .nav-link.active:before,
  .feah-before-after-tab-btn .nav-pills .show>.nav-link:before,
  .feah-before-after-tab-btn .nav-pills .nav-link:before {
    top: 0;
    left: -50px;
    width: 4px;
    content: "";
    height: 0px;
    position: absolute;
    border-radius: 5px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color-2);
  }

  .feah-before-after-tab-btn .nav-pills .nav-link.active {
    position: relative;
    color: var(--main-color-2);
  }

  .feah-before-after-tab-btn .nav-pills .nav-link.active:before {
    height: 50px;
  }

  .feah-before-after-tab-btn li {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 38px;
  }

  .beforeafter-wrap .twentytwenty-handle {
    border: none;
    background-color: var(--main-color-2);
  }

  .beforeafter-wrap .twentytwenty-after-label:before {
    content: "AFTER DESIGN";
  }

  .beforeafter-wrap .twentytwenty-before-label:before {
    content: "BEFORE DESIGN";
  }

  .beforeafter-wrap .twentytwenty-before-label:before,
  .beforeafter-wrap .twentytwenty-after-label:before {
    top: auto;
    bottom: 30px;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 15px;
    line-height: 30px;
    color: var(--white-color);
    background-color: var(--main-color-2);
  }

  .beforeafter-wrap .twentytwenty-horizontal .twentytwenty-before-label:before {
    left: 30px;
  }

  .beforeafter-wrap .twentytwenty-horizontal .twentytwenty-after-label:before {
    right: 30px;
  }

  .beforeafter-wrap .twentytwenty-overlay:hover {
    background-color: transparent;
  }

  .beforeafter-wrap .twentytwenty-before-label,
  .beforeafter-wrap .twentytwenty-after-label {
    opacity: 1;
  }

  /*Service Section*/
  /*----------------------------------------------------*/
  .feah-service-section {
    overflow: hidden;
    padding: 80px 0px;
  }

  .feah-service-text-area {
    max-width: 400px;
  }

  .feah-service-text-area .feah-section-title {
    padding-bottom: 35px;
  }

  .feah-service-text-area .feah-section-title h2 {
    font-size: 28px;
    line-height: 44px;
  }

  .feah-service-text-area .feah-section-title h2 span:before {
    bottom: 5px;
  }

  .feah-service-list-wraper li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
  }

  .feah-service-list-wraper li:before {
    left: 0;
    top: 8px;
    width: 20px;
    content: "";
    height: 20px;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url("/assets/icon/check.png");
  }

  .feah-service-item {
    padding: 40px 35px;
    position: relative;
    border: 1px solid #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-service-item .bg-img {
    left: 0;
    right: 0;
    opacity: 0;
    top: 45px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-service-item:before {
    left: 0;
    right: 0;
    bottom: 0;
    width: 0%;
    height: 5px;
    content: "";
    margin: 0 auto;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color-2);
  }

  .feah-service-item.active,
  .feah-service-item:hover {
    border: 1px solid rgba(0, 0, 0, 0.168627451);
  }

  .feah-service-item.active:before,
  .feah-service-item:hover:before {
    width: 100%;
  }

  .feah-service-item.active .bg-img,
  .feah-service-item:hover .bg-img {
    top: 25px;
    opacity: 1;
  }

  .feah-service-item .service-serial {
    font-size: 40px;
    color: rgba(0, 0, 0, 0.1490196078);
    font-weight: 600;
    margin-bottom: 5px;
  }

  .feah-service-item .service-title-text h3 {
    color: #020202;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .feah-service-item .service-title-text p {
    padding-bottom: 15px;
  }

  .feah-service-item .service-title-text .more-btn {
    font-size: 20px;
  }

  .feah-service-item .service-title-text .more-btn:hover {
    color: var(--main-color-2);
  }

  .feah-service-section-2 {
    z-index: 1;
  }

  .feah-service-section-2 .container {
    max-width: 1490px;
  }

  .feah-service-section-2 .feah-section-title {
    max-width: 520px;
    padding-bottom: 0;
    padding-top: 60px;
  }

  .feah-service-top-content-2 .feah-feature-item {
    margin-top: 30px;
  }

  .feah-service-top-content-2 .feah-feature-item:after {
    right: 5px;
  }

  .feah-service-top-content-2 .top-service-item {
    margin-left: 180px;
  }

  .feah-service-content-2 .feah-feature-item .feah-feature-text p {
    max-width: 235px;
  }

  .feah-service-content-2 .feah-feature-item {
    margin-top: 90px;
  }

  .feah-service-content-2 .feah-feature-item:after {
    right: 5px;
  }

  .feah-service-content-2 .feah-feature-item:last-child:after {
    display: block;
  }

  .feah-service-content-2 .col-lg-3:nth-child(3) .feah-feature-item:after,
  .feah-service-content-2 .col-lg-3:nth-child(6) .feah-feature-item:after {
    display: none;
  }

  .feah-service-content-2 .feah-feature-item .feah-feature-icon:before {
    display: none;
  }

  .feah-service-section-2 {
    z-index: 1;
    padding: 40px 0px 0px;
  }

  .feah-service-section-2:before {
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    width: 100%;
    height: 700px;
    position: absolute;
    background-color: #fbf8f6;
    background-image: url(../img/bg/shape-bg.png);
    background-repeat: no-repeat;
  }

  .feah-service-section-2:after {
    left: 0;
    top: 40px;
    z-index: -1;
    content: "";
    width: 830px;
    height: 275px;
    position: absolute;
    background-color: #fff;
  }

  .feah-service-content-2 {
    padding-bottom: 130px;
  }

  .feah-service-img-2 {
    right: 0;
    z-index: 1;
    bottom: 20px;
    position: absolute;
  }

  .feah-service-img-2:before {
    top: 20px;
    width: 100%;
    content: "";
    z-index: -1;
    height: 100%;
    right: -20px;
    position: absolute;
    background-color: var(--main-color-2);
  }

  .feah-service-img-2 .circle-shape {
    top: -40px;
    left: -75px;
    z-index: -1;
  }

  .feah-service-text-wrapper {
    padding-top: 150px;
  }

  .feah-service-text-area-2 {
    color: #000000;
  }

  .feah-service-text-area-2 span {
    font-size: 15px;
  }

  .feah-service-text-area-2 h3 {
    font-size: 20px;
  }

  .feah-service-text-area-2:before {
    top: 25px;
    right: 50px;
    height: 2px;
    content: "";
    width: 200px;
    position: absolute;
    background-color: var(--main-color-2);
  }

  .feah-service-text-content {
    max-width: 970px;
  }

  .feah-service-top-content-2 {
    padding-left: 85px;
  }

  /*About Section*/
  /*----------------------------------------------------*/
  .feah-about-section {
    z-index: 1;
    overflow: hidden;
    padding: 95px 0px 45px;
    background-color: #fcfcfc;
  }

  .feah-about-section:before {
    top: 0;
    right: 0;
    width: 56.5%;
    z-index: -1;
    content: "";
    height: 100%;
    position: absolute;
    background-color: #f8f6f4;
  }

  .feah-about-sub-text {
    margin-bottom: 40px;
  }

  .feah-about-sub-text h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  .feah-about-sub-text p {
    line-height: 35px;
  }

  .feah-about-feature-list li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
  }

  .feah-about-feature-list li:before {
    left: 0;
    top: 8px;
    width: 30px;
    content: "";
    height: 30px;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url("/assets/icon/check.png");
  }

  .feah-about-sponsor-scroller {
    padding-left: 20px;
  }

  .feah-about-sponsor-wrapper {
    margin: 0px -10px;
  }

  .feah-about-sponsor-wrapper .feah-sponsor-item {
    height: 90px;
    width: 170px;
    margin: 0px 10px;
    padding-top: 25px;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-about-sponsor-wrapper .feah-sponsor-item img {
    margin: 0 auto;
  }

  .feah-about-sponsor-wrapper .feah-sponsor-item:hover {
    background-color: var(--main-color-2);
  }

  .feah-about-experience-scoller {
    margin-top: 80px;
  }

  .feah-about-experience {
    width: 170px;
    height: 275px;
    border: 1px solid #498951;
  }

  .feah-about-experience .feah-about-exp-text h3 {
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 15px;
    color: var(--main-color-2);
  }

  .feah-about-experience .feah-about-exp-text h3 sub {
    bottom: 0;
  }

  .feah-about-experience .feah-about-exp-text p {
    color: #32302E;
    font-size: 14px;
    line-height: 1.3;
    max-width: 105px;
    font-family: var(--heading-2);
  }

  .feah-about-scroller-wrapper {
    width: 605px;
    height: 275px;
    margin-left: 35px;
    overflow-y: scroll;
  }

  .feah-about-scroller-wrapper::-webkit-scrollbar {
    width: 4px;
  }

  .feah-about-scroller-wrapper::-webkit-scrollbar-track {
    background: #EAEAEA;
  }

  .feah-about-scroller-wrapper::-webkit-scrollbar-thumb {
    background: var(--main-color-2);
  }

  .feah-about-scroller-item {
    margin-bottom: 50px;
  }

  .feah-about-scroller-item h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 20px;
  }

  .feah-about-scroller-item p {
    line-height: 35px;
  }

  .feah-about-section-2 {
    z-index: 1;
    padding: 100px 0px;
  }

  .feah-about-section-2 .container {
    max-width: 1490px;
  }

  .feah-about-section-2 .feah-about-counter-img {
    left: 0;
    right: 0;
    top: 70px;
    z-index: -1;
    margin: 0 auto;
    text-align: center;
  }

  .feah-about-img-wrapper-2 .feah-about-img-2 {
    z-index: 1;
    max-width: 335px;
  }

  .feah-about-img-wrapper-2 .feah-about-img-2:before {
    top: 0;
    z-index: -1;
    width: 100%;
    content: "";
    height: 100%;
    right: -20px;
    position: absolute;
    border-radius: 170px;
    background-color: var(--main-color-2);
  }

  .feah-about-img-wrapper-2 .feah-about-exp-text {
    top: 50%;
    z-index: 1;
    left: -20px;
    color: #000;
    font-weight: 500;
    padding: 30px 5px;
    background-color: #fff;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.0509803922);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.0509803922);
  }

  .feah-about-img-wrapper-2 .feah-about-exp-text span {
    color: var(--main-color-2);
  }

  .feah-about-img-wrapper-2 .feah-about-mail {
    bottom: 0;
    z-index: 1;
    left: -70px;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  .feah-about-img-wrapper-2 .feah-about-mail img {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .feah-about-img-wrapper-2 .feah-about-mail span {
    top: 3px;
    left: -45px;
    color: #fff;
    position: relative;
    padding: 10px 40px;
    border-radius: 30px;
    background-color: var(--main-color-2);
  }

  .feah-about-counter {
    padding-top: 110px;
  }

  .feah-about-counter h3 {
    line-height: 1;
    font-size: 200px;
    font-weight: 700;
    color: var(--main-color-2);
  }

  .feah-about-counter p {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }

  .feah-about-counter .counter-text {
    margin-top: 80px;
  }

  .feah-about-service-area {
    width: 350px;
    padding: 15px;
    border: 1px solid rgba(222, 212, 212, 0.6);
  }

  .feah-about-service-area .feah-about-service {
    padding: 25px;
  }

  .feah-about-service-area .feah-about-service .about-service-icon {
    margin-right: 25px;
  }

  .feah-about-service-area .feah-about-service .about-service-title h3 {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }

  .feah-about-service-area .feah-about-service .about-service-title p {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.2784313725);
  }

  .feah-about-service-area .feah-about-service .feah-service-list-item {
    margin-top: 55px;
  }

  .feah-about-service-area .feah-about-service .feah-service-list-item li {
    color: #020202;
    margin-bottom: 28px;
  }

  .feah-about-service-area .feah-about-service .feah-service-list-item li img {
    margin-right: 15px;
  }

  .feah-about-service-area .about-service-btn a {
    color: #fff;
    width: 100%;
    height: 45px;
    font-size: 14px;
    background-color: var(--main-color-2);
  }

  .feah-about-service-area .about-service-btn a:hover {
    background-color: #000000;
  }

  /*Work Process Section*/
  /*---------------------------------------------------*/
  .feah-work-process-section {
    z-index: 1;
    margin-top: 50px;
    overflow: hidden;
  }

  .feah-work-process-section .container {
    max-width: 1490px;
  }

  .feah-work-process-section .feah-wp-exp-text1,
  .feah-work-process-section .feah-wp-exp-text2 {
    left: 130px;
    color: #000000;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .feah-work-process-section .feah-wp-exp-text1 span,
  .feah-work-process-section .feah-wp-exp-text2 span {
    color: var(--main-color-2);
  }

  .feah-work-process-section .feah-wp-exp-text1 {
    top: 50px;
  }

  .feah-work-process-section .feah-wp-exp-text2 {
    bottom: 50px;
  }

  .feah-work-process-section .feah-work-pro-img {
    top: 0;
    right: 0;
    z-index: -1;
  }

  .feah-work-process-system-content {
    background-color: #fdfdfd;
  }

  .feah-work-process-item-wrap {
    padding: 0px 15px;
  }

  .feah-work-process-item {
    max-width: 260px;
    position: relative;
  }

  .feah-work-process-item:after {
    top: 15px;
    right: -25px;
    width: 1px;
    height: 100%;
    content: "";
    position: absolute;
    background-color: rgba(217, 217, 217, 0.4392156863);
  }

  .feah-work-process-item .feah-work-porcess-icon {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 1px solid rgba(210, 143, 81, 0.3490196078);
  }

  .feah-work-process-item .feah-work-process-serial {
    opacity: 0.5;
    font-size: 60px;
    font-weight: 500;
    color: var(--main-color-2);
    font-family: var(--heading-2);
  }

  .feah-work-process-item .feah-work-process-text {
    margin-top: 10px;
  }

  .feah-work-process-item .feah-work-process-text h3 {
    color: #020202;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  .feah-work-process-item .feah-work-process-text p {
    max-width: 265px;
  }

  .feah-work-process-system {
    right: 0;
    top: 50%;
    max-width: 1070px;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .feah-work-process-system .feah-work-process-system-content {
    padding: 65px 55px;
  }

  .feah-work-process-title {
    margin-top: 35px;
    margin-left: 15px;
  }

  .feah-work-process-title h3 {
    color: #000000;
    font-size: 38px;
    font-weight: 700;
  }

  .feah-work-process-title h3 span {
    font-weight: 400;
    color: var(--main-color-2);
  }

  /*Contact Section*/
  /*---------------------------------------------------*/
  .feah-contact-section {
    padding: 55px 0px 60px;
  }

  .feah-contact-img {
    z-index: 1;
    top: -110px;
    margin-bottom: -170px;
  }

  .feah-contact-img img {
    filter: grayscale(100%) !important;
  }

  .feah-contact-img img:hover {
    filter: grayscale(0%) !important;
  }

  .feah-contact-img .img-shape {
    top: 45px;
    width: 80%;
    z-index: -1;
    height: 85%;
    right: -20px;
    position: absolute;
    background-color: var(--main-color-2);
  }

  .feah-counter-wrapper {
    margin-top: 90px;
  }

  .feah-counter-item h3 {
    font-size: 32px;
    font-weight: 500;
    color: var(--main-color-2);
  }

  .feah-counter-item p {
    color: #000000;
  }

  .feah-contact-form-wrapper {
    padding: 100px;
    border: 2px dashed #e5e5e5;
    background-color: #fcfcfc;
    margin: 50px 80px;

  }

  a.reg-link {
    padding-inline-start: 20px;
  }

  .feah-contact-form input,
  .feah-contact-form textarea {
    width: 100%;
    height: 50px;
    border: none;
    margin-bottom: 40px;
    border-bottom: 1px solid #E0E0E0;
    padding: 0 18px;
  }

  .feah-contact-form input::-webkit-input-placeholder,
  .feah-contact-form textarea::-webkit-input-placeholder {
    color: #817F7E;
  }

  .feah-contact-form input::-moz-placeholder,
  .feah-contact-form textarea::-moz-placeholder {
    color: #817F7E;
  }

  .feah-contact-form input:-ms-input-placeholder,
  .feah-contact-form textarea:-ms-input-placeholder {
    color: #817F7E;
  }

  .feah-contact-form input::-ms-input-placeholder,
  .feah-contact-form textarea::-ms-input-placeholder {
    color: #817F7E;
  }

  .feah-contact-form input::placeholder,
  .feah-contact-form textarea::placeholder {
    color: #817F7E;
  }

  .feah-contact-form textarea {
    height: 90px;
  }

  .feah-contact-form button {
    color: #fff;
    height: 58px;
    width: 252px;
    border: none;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color-2);
  }

  .feah-contact-form button i {
    margin-left: 5px;
  }

  .feah-contact-form button:hover {
    background-color: #000;
  }

  .feah-contact-section-2 {
    overflow: hidden;
    padding-bottom: 20px;
  }

  .feah-contact-section-2 .feah-contact-form-wrapper {
    max-width: 750px !important;
  }

  .feah-contact-map-form .feah-contact-map {
    width: 50%;
    -webkit-filter: invert(90%);
    filter: invert(90%);
    background-color: #fff;
  }

  .feah-contact-map-form .feah-contact-form-wrapper {
    width: 50%;
    max-width: 100%;
    padding-top: 80px;
    padding-left: 60px;
    padding-right: 15px;
  }

  .feah-testimonial-section-2 {
    overflow: hidden;
    padding: 80px 0px 160px;
  }

  .feah-testimonial-section-2 .feah-testimonial-bg-2 {
    bottom: 0;
    left: -50px;
  }

  .feah-testimonial-2-slider-wrapper {
    margin: 0 auto;
    max-width: 1270px;
  }

  .feah-testimonial-img-2 {
    z-index: 1;
    width: 360px;
    margin-right: 120px;
  }

  .feah-testimonial-img-2 .inner-img {
    width: 340px;
    height: 335px;
    overflow: hidden;
    border-radius: 100%;
  }

  .feah-testimonial-img-2 .testimonial-social {
    left: 20px;
    bottom: -10px;
    padding: 3px 15px;
    position: absolute;
    background-color: #fff;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    -webkit-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.0784313725);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.0784313725);
  }

  .feah-testimonial-img-2 .testimonial-social li {
    margin: 0px 10px;
  }

  .feah-testimonial-img-2 .testimonial-social li a {
    color: #1E1919;
  }

  .feah-testimonial-img-2 .testimonial-social li a:hover {
    color: var(--main-color-2);
  }

  .feah-testimonial-img-2 .feah-shape1 {
    left: -50px;
    bottom: -65px;
  }

  .feah-testimonial-img-2 .feah-shape2 {
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .feah-testimonial-img-2 .feah-testimonial-quote {
    top: -35px;
    right: -10px;
    line-height: 0;
    color: #DADADA;
    font-size: 55px;
  }

  .feah-testimonial-img-text-2 {
    padding-top: 40px;
  }

  .feah-textimonial-text-2 h3 {
    color: #020202;
    font-size: 38px;
    padding-bottom: 20px;
  }

  .feah-textimonial-text-2 p {
    color: #000000;
    font-size: 20px;
    line-height: 45px;
    padding-bottom: 20px;
  }

  .feah-testimonial-author-2 h4 {
    color: #445658;
    font-size: 16px;
    margin-right: 25px;
  }

  .feah-testimonial-author-2 span {
    color: #445658;
    font-size: 13px;
    letter-spacing: 5px;
  }

  .feah-testimonial-slider-2 .slick-dots li {
    color: #000;
    position: relative;
  }

  .feah-testimonial-slider-2 .slick-dots li:after {
    top: 0;
    right: -7px;
    content: "/";
    position: absolute;
  }

  .feah-testimonial-slider-2 .slick-dots li:last-child:after {
    display: none;
  }

  .feah-testimonial-slider-2 .slick-dots li.slick-active {
    color: var(--main-color-2);
  }

  .feah-testimonial-slider-2 .ar-tst_2_left_arrow,
  .feah-testimonial-slider-2 .ar-tst_2_right_arrow {
    border: none;
    background-color: transparent;
  }

  .feah-testimonial-2-slider-wrapper .ar-tst_2_left_arrow,
  .feah-testimonial-2-slider-wrapper .ar-tst_2_right_arrow {
    border: none;
    position: relative;
    background-color: transparent;
  }

  .feah-testimonial-2-slider-wrapper .ar-tst_2_left_arrow {
    top: -5px;
    left: -40px;
  }

  .feah-testimonial-2-slider-wrapper .ar-tst_2_right_arrow {
    top: -5px;
    right: -40px;
  }

  .feah-testimonial-2-slider-wrapper .carousel_nav {
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    position: absolute;
  }

  /*Project Section*/
  /*---------------------------------------------------*/
  .feah-project-section-2 {
    overflow: hidden;
    padding: 100px 0px 50px;
    background-color: #232A31;
  }

  .feah-project-section-2 .feah-section-title {
    max-width: 480px;
    padding-bottom: 0;
  }

  .feah-project-section-2 .feah-section-title h2 {
    color: #fff;
  }

  .feah-project-slider-area {
    margin: 0px -10px;
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .ar-pro_left_arrow,
  .ar-pro_right_arrow {
    width: 55px;
    height: 40px;
    border: none;
    font-size: 20px;
    margin-left: 10px;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    color: var(--main-color-2);
  }

  .ar-pro_left_arrow:hover,
  .ar-pro_right_arrow:hover {
    color: #fff;
    background-color: var(--main-color-2);
  }

  .feah-project-item-2 {
    width: 368px;
    margin: 0px 10px;
  }

  .feah-project-item-2 .serial-no {
    top: 0px;
    right: 0px;
    opacity: 0;
    width: 45px;
    height: 45px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    border-radius: 23px;
    border-top-right-radius: 0;
    background-color: #FFFFFF;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-project-item-2 .feah-project-text-2 {
    left: 0;
    right: 0;
    width: 90%;
    opacity: 0;
    bottom: 0px;
    height: 70px;
    margin: 0 auto;
    padding: 0px 15px;
    background-color: #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-project-item-2 .feah-project-text-2 h3 {
    color: #020202;
    font-size: 16px;
  }

  .feah-project-item-2 .feah-project-text-2 .more_btn {
    color: #fff;
    width: 50px;
    height: 45px;
    font-size: 20px;
    background-color: var(--main-color-2);
  }

  .feah-project-item-2:hover .serial-no {
    top: 12px;
    opacity: 1;
    right: 12px;
  }

  .feah-project-item-2:hover .feah-project-text-2 {
    opacity: 1;
    bottom: 20px;
  }

  .feah-project-section-3 {
    z-index: 1;
    padding: 100px 0px 120px;
  }

  .feah-project-section-3 .feah-section-title {
    margin: 0 auto;
    max-width: 750px;
  }

  .feah-project-section-3 .feah-project-pro-img {
    top: 0;
    right: 0;
    z-index: -1;
  }

  .feah-project-content-3 .feah-before-after-tab-btn .nav-pills .nav-link {
    color: #000000;
  }

  .feah-project-content-3 .feah-before-after-tab-btn .nav-pills .nav-link.active {
    color: var(--main-color-2);
    font-weight: 600;
  }

  .feah-project-item-3 {
    max-width: 938px;
  }

  .feah-project-item-3 .feah-project-text {
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 45px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .feah-project-item-3 .feah-project-text h3 {
    color: #fff;
    font-size: 20px;
  }

  .feah-project-item-3 .feah-project-text .read-more-btn {
    width: 42px;
    height: 42px;
    color: #000000;
    border-radius: 23px;
    background-color: #fff;
    border-top-right-radius: 0;
  }

  .feah-project-item-3 .feah-project-text .read-more-btn:hover {
    color: #fff;
    background-color: var(--main-color-2);
  }

  /*News Latter Section*/
  /*---------------------------------------------------*/
  .feah-newslatter-content {
    max-width: 870px;
    padding-top: 75px;
  }

  .feah-newslatter-cta {
    width: 270px;
  }

  .feah-newslatter-cta .inner-icon {
    margin-right: 15px;
  }

  .feah-newslatter-cta .inner-text {
    color: #fff;
    font-size: 14px;
  }

  .feah-newslatter-cta .inner-text span {
    display: block;
  }

  .feah-newslatter-cta .inner-text .cta-no {
    font-weight: 500;
  }

  .feah-newslatter-cta .inner-text .cta-text a {
    color: var(--main-color-2);
  }

  .newslatter-form {
    width: 470px;
  }

  .newslatter-form:before {
    top: 25px;
    left: 30px;
    width: 1px;
    content: "";
    height: 12px;
    position: absolute;
    background-color: #949392;
  }

  .newslatter-form .bg-icon {
    left: 0;
    top: 15px;
  }

  .newslatter-form input {
    width: 100%;
    border: none;
    height: 60px;
    padding-left: 45px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6705882353);
  }

  .newslatter-form input::-webkit-input-placeholder {
    color: #949392;
  }

  .newslatter-form input::-moz-placeholder {
    color: #949392;
  }

  .newslatter-form input:-ms-input-placeholder {
    color: #949392;
  }

  .newslatter-form input::-ms-input-placeholder {
    color: #949392;
  }

  .newslatter-form input::placeholder {
    color: #949392;
  }

  .newslatter-form button {
    right: 0;
    top: 20px;
    color: #fff;
    border: none;
    font-weight: 500;
    position: absolute;
    font-family: var(--heading-2);
    background-color: transparent;
  }

  /*Testimonial Section*/
  /*---------------------------------------------------*/
  .feah-testimonial-section {
    z-index: 1;
    padding-bottom: 120px;
  }

  .feah-testimonial-section:before {
    top: 40px;
    left: 0;
    z-index: -1;
    content: "";
    width: 952px;
    height: 150px;
    position: absolute;
    background-color: #f4f2f1;
  }

  .feah-testimonial-section .feah-section-title {
    max-width: 450px;
    padding-bottom: 0px;
  }

  .feah-testimonial-top-content {
    max-width: 760px;
    padding-top: 60px;
  }

  .feah-testimonial-carousel-btn .carousel-control-next,
  .feah-testimonial-carousel-btn .carousel-control-prev {
    opacity: 1;
    width: 60px;
    color: #fff;
    height: 55px;
    position: static;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: var(--main-color-2);
  }

  .feah-testimonial-carousel-btn .carousel-control-next:hover,
  .feah-testimonial-carousel-btn .carousel-control-prev:hover {
    color: #000000;
    background-color: #fff;
  }

  .feah-testimonial-carousel-btn .carousel-control-prev-icon,
  .feah-testimonial-carousel-btn .carousel-control-next-icon {
    background-image: none;
  }

  .feah-testimonial-slider {
    padding-top: 95px;
  }

  .feah-testimonial-slider .carousel-indicators {
    margin: 0;
    position: static;
    display: inline-block;
  }

  .feah-testimonial-slider .carousel-indicators:hover [data-bs-target] {
    margin-left: 0px;
  }

  .feah-testimonial-slider .carousel-indicators:hover [data-bs-target]:nth-child(1) {
    margin-left: 0px;
  }

  .feah-testimonial-slider .carousel-indicators [data-bs-target] {
    opacity: 1;
    width: 50px;
    height: 50px;
    border: none;
    text-indent: 0;
    margin-right: 0;
    overflow: hidden;
    margin-left: -20px;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-testimonial-slider .carousel-indicators [data-bs-target]:nth-child(1) {
    margin-left: 0;
  }

  .feah-testimonial-slider .carousel-indicators [data-bs-target].active {
    border: 3px solid var(--main-color-2);
  }

  .feah-testimonial-slider .carousel-indicators-wrap {
    margin-top: 40px;
  }

  .feah-testimonial-slider .carousel-indicators-wrap .more-testimonial-btn {
    margin-left: 15px;
  }

  .feah-testimonial-slider .carousel-indicators-wrap .more-testimonial-btn a {
    color: #000000;
    font-weight: 500;
  }

  .feah-testimonial-slider .carousel-indicators-wrap .more-testimonial-btn a i {
    margin-left: 5px;
  }

  .feah-testimonial-item h3 {
    color: #020202;
    font-size: 30px;
    padding-bottom: 20px;
  }

  .feah-testimonial-item p {
    color: #000000;
    max-width: 805px;
    line-height: 30px;
  }

  .feah-testimonial-img-wrap .inner-img {
    z-index: 1;
    top: -90px;
    padding-right: 30px;
    margin-bottom: -90px;
  }

  .feah-testimonial-img-wrap .inner-img:before {
    right: 0;
    top: 30px;
    width: 90%;
    z-index: -1;
    content: "";
    height: 100%;
    position: absolute;
    background-color: var(--main-color-2);
  }

  /*Blog Section*/
  /*---------------------------------------------------*/
  .feah-blog-section {
    overflow: hidden;
    padding-bottom: 115px;
  }

  .feah-blog-section .feah-section-title {
    margin: 0 auto;
    max-width: 570px;
  }

  .feah-blog-item {
    max-width: 445px;
  }

  .feah-blog-item .blog-img {
    overflow: hidden;
    margin-bottom: 20px;
  }

  .feah-blog-item .blog-img img {
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-blog-item .blog-category {
    left: 30px;
    color: #fff;
    width: 145px;
    height: 35px;
    bottom: 30px;
    font-weight: 700;
    background-color: #000000;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  .feah-blog-item .blog-meta {
    margin-bottom: 12px;
  }

  .feah-blog-item .blog-meta li {
    margin-right: 20px;
  }

  .feah-blog-item .blog-meta li a {
    color: #000000;
  }

  .feah-blog-item .blog-meta li:last-child {
    margin-right: 0;
  }

  .feah-blog-item .blog-meta i {
    margin-right: 5px;
    color: var(--main-color-2);
  }

  .feah-blog-item .blog-title h3 {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
  }

  .feah-blog-item .blog-title h3:hover {
    color: var(--main-color-2);
  }

  .feah-blog-item .blog-read-more {
    margin-top: 20px;
  }

  .feah-blog-item .blog-read-more a {
    color: #000000;
  }

  .feah-blog-item .blog-read-more a i {
    margin-left: 5px;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease;
  }

  .feah-blog-item .blog-read-more a:hover {
    color: var(--main-color);
  }

  .feah-blog-item .blog-read-more a:hover i {
    margin-left: 8px;
  }

  .feah-blog-item .blog-read-more a:hover {
    color: var(--main-color-2);
  }

  .feah-blog-item:hover .blog-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .feah-blog-item:hover .blog-category {
    background-color: var(--main-color-2);
  }

  .feah-blog-section-2 {
    padding: 110px 0px 70px;
    background-color: #fbf8f6;
  }

  .feah-blog-section-2 .feah-section-title {
    margin: 0 auto;
    max-width: 650px;
  }

  .feah-blog-section-2 .feah-blog-item {
    background-color: #fff;
  }

  .feah-blog-section-2 .feah-blog-item .blog-text {
    padding: 0px 25px 30px;
  }

  .feah-blog-more-btn {
    margin-top: 40px;
  }

  .feah-blog-more-btn a {
    color: #fff;
    width: 200px;
    height: 65px;
    background-color: var(--main-color-2);
  }

  .feah-blog-more-btn a i {
    margin-left: 5px;
  }

  .feah-blog-more-btn a:hover {
    background-color: #000000;
  }

  /*Map Social Section*/
  /*---------------------------------------------------*/
  .feah-map-section {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1490196078);
  }

  .feah-map-wrap {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .feah-map-info {
    top: 50%;
    right: 15%;
    position: absolute;
    background-color: #fff;
    padding: 50px 40px 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-bottom: 3px solid var(--main-color-2);
  }

  .feah-map-info-item {
    margin-bottom: 40px;
  }

  .feah-map-info-item .info-title {
    margin-bottom: 10px;
  }

  .feah-map-info-item .info-title span {
    font-size: 24px;
    margin-right: 15px;
    color: var(--main-color-2);
  }

  .feah-map-info-item .info-title h3 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
  }

  .feah-map-info-item .info-text {
    color: #000000;
    max-width: 250px;
  }

  .feah-map-info-item .info-text li i {
    margin-right: 15px;
    color: var(--main-color-2);
  }

  .feah-social-content .feah-social-item {
    width: 16.667%;
  }

  .feah-social-content .feah-social-item a {
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #D9D9D9;
  }

  .feah-social-content .feah-social-item a:hover i {
    color: var(--main-color-2);
  }

  .feah-social-content .feah-social-item i {
    color: #000000;
    margin-right: 8px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
  }

  /*Footer Section*/
  /*---------------------------------------------------*/
  .feah-newslatter-content-area {
    padding-bottom: 50px;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(222, 226, 238, 0.0784313725);
  }

  .feah-newslatter-content-area .feah-newslatter-text h3 {
    color: #fff;
    font-size: 38px;
    font-weight: 500;
  }

  .feah-newslatter-content-area .feah-newslatter-text h3 span {
    color: var(--main-color-2);
  }

  .feah-newslatter-content-area .feah-newslatter-text p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .feah-newslatter-content-area .feah-newslatter-form input {
    width: 495px;
    height: 60px;
    border: none;
    max-width: 100%;
    padding-left: 20px;
    background-color: rgba(255, 255, 255, 0.1019607843);
  }

  .feah-newslatter-content-area .feah-newslatter-form button {
    top: 0;
    right: 0;
    color: #fff;
    width: 160px;
    height: 60px;
    border: none;
    position: absolute;
    background-color: var(--main-color-2);
  }

  .feah-newslatter-content-area .feah-newslatter-form button i {
    margin-left: 5px;
  }

  .feah-footer-section {
    padding-top: 140px;
    overflow: hidden;
    background-color: #000000;
    background-image: url("../img/bg/ar-ft-bg.png");
  }

  .feah-footer-section .feah-footer-mail {
    top: 60px;
    left: 20px;
    color: #fff;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .feah-footer-section .feah-footer-address {
    left: 20px;
    color: #fff;
    bottom: 50px;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }

  .feah-footer-section .feah-footer-address b {
    font-weight: 400;
    color: var(--main-color-2);
  }

  nav.main-navigation {
    padding-left: 20px;
  }

  .feah-footer-content {
    padding-bottom: 70px;
  }

  .feah-footer-content .feah-footer-widget .widget-title {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 25px;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .brand-logo {
    margin-bottom: 25px;
    width: 100% !important;
    max-width: 100% !important;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .brand-logo img {
    width: 300px;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .logo-text {
    color: #B4B4B4;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .logo-cta-info li {
    color: #B4B4B4;
    margin-bottom: 20px;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .logo-cta-info li:last-child {
    margin-bottom: 0;
  }

  .feah-footer-content .feah-footer-widget .logo-widget .logo-cta-info li i {
    color: #fff;
    margin-right: 15px;
  }

  .feah-footer-content .feah-footer-widget .menu-widget {
    padding-left: 50px;
  }

  .feah-footer-content .feah-footer-widget .menu-widget li {
    margin-bottom: 15px;
  }

  .feah-footer-content .feah-footer-widget .menu-widget li:last-child {
    margin-bottom: 0;
  }

  .feah-footer-content .feah-footer-widget .menu-widget li a {
    color: #B4B4B4;
    font-family: var(--heading-2);
  }

  .feah-footer-content .feah-footer-widget .menu-widget li a:hover {
    color: var(--main-color-2);
  }

  .feah-footer-content .feah-footer-widget .award-widget .aw-title {
    color: #B4B4B4;
  }

  .feah-footer-content .feah-footer-widget .award-widget .aw-number {
    color: #fff;
    line-height: 1;
    font-size: 55px;
    font-weight: 600;
    margin-left: 50px;
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap {
    margin: 40px 0px;
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a {
    width: 110px;
    height: 115px;
    height: 115px;
    display: block;
    border-radius: 5px;
    position: relative;
    margin-right: 30px;
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a:before {
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    opacity: 0;
    height: 100%;
    position: absolute;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a i {
    left: 0;
    right: 0;
    top: 60%;
    opacity: 0;
    color: #fff;
    position: absolute;
    text-align: center;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a:hover:before {
    opacity: 1;
  }

  .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a:hover i {
    top: 50%;
    opacity: 1;
  }

  .feah-footer-content .feah-footer-widget .aw-btn-wrap {
    padding-bottom: 10px;
  }

  .feah-footer-content .feah-footer-widget .aw-btn-wrap img {
    margin-right: 5px;
  }

  .feah-footer-content .feah-footer-widget .aw-btn-wrap span {
    color: #922129;
  }

  .feah-footer-content .feah-footer-widget .aw-btn-wrap .aw-line {
    bottom: 0;
    left: 40px;
  }

  .feah-footer-copyright {
    padding: 35px 0px;
  }

  .feah-footer-copyright-social a {
    color: #fff !important;
    width: 45px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    border-radius: 2px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
  }

  .feah-footer-copyright-social a:hover {
    background-color: var(--main-color-2);
  }

  /*Responsive*/
  /*----------------------------------------------------*/
  @media screen and (max-width: 1600px) {

    .feah-slider-section .feah-slider-side1,
    .feah-slider-section .feah-slider-side2 {
      display: none;
    }

    .feah-feature-img {
      margin-left: 15px;
    }

    .feah-feature-item {
      width: 230px;
    }

    .feah-feature-img .feah-ft-shape {
      display: none;
    }

    .feah-feature-item:after {
      display: none;
    }

    .feah-project-item .project-icon {
      width: 110px;
      margin-bottom: 40px;
    }

    .feah-project-item .hover-item .inner-title h3 {
      font-size: 20px;
      line-height: 30px;
    }

    .feah-project-item .hover-item .inner-text .inner-icon {
      width: 40px;
    }

    .feah-project-item .hover-item .inner-text .inner-serial {
      font-size: 30px;
    }

    .feah-newslatter-content {
      max-width: 790px;
    }

    .feah-footer-section .feah-footer-address,
    .feah-footer-section .feah-footer-mail {
      display: none;
    }

    .feah-footer-content {
      padding-bottom: 30px;
    }

    .feah-service-section-2:after {
      width: 585px;
    }

    .feah-service-img-2 .circle-shape {
      left: -35px;
    }

    .feah-service-img-2:before {
      display: none;
    }

    .feah-header-section.feah-header-style-two .header-main-menu-wrapper {
      padding: 20px 20px;
    }

    .slider-side-content {
      display: none;
    }

    .feah-slider-section-2 .feah-banner-img1 {
      left: 0;
    }

    .feah-slider-section-2 .feah-banner-img2 {
      left: 80px;
    }

    .feah-slider-section-2 .feah-banner-img3 {
      left: 0;
    }

    .feah-slider-section-2 .feah-banner-img4 {
      left: 120px;
    }

    .feah-slider-section-2 .feah-banner-img5 {
      right: 0;
    }

    .feah-slider-section-2 .feah-banner-img7 {
      display: none;
    }

    .feah-slider-section-2 .feah-banner-counter {
      display: none;
    }

    .feah-about-img-wrapper-2 .feah-about-exp-text {
      left: 0;
    }

    .feah-service-top-content-2 .top-service-item {
      margin-left: 50px;
    }

    .feah-service-top-content-2 {
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 1300px) {
    .feah-feature-img {
      top: -40px;
      width: 285px;
      margin-bottom: -40px;
    }

    .feah-project-item {
      width: 50%;
      margin: 0 auto;
      max-width: 475px;
      border-right: 0;
    }

    .feah-project-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-project-item .project-text h3 {
      font-size: 20px;
      line-height: 30px;
    }

    .feah-testimonial-img-wrap .inner-img {
      top: 0;
      padding-top: 50px;
    }

    .feah-newslatter-content {
      max-width: 100%;
    }

    .feah-service-img-2 .circle-shape {
      display: none;
    }

    .feah-service-text-area-2:before {
      width: 140px;
      right: 140px;
    }
  }

  @media screen and (max-width: 1100px) {
    .feah-header-section .main-navigation li {
      margin: 0px 25px;
    }

    .feah-header-section .header-top-content .header-top-cta {
      display: none;
    }

    .feah-header-section .header-top-content .brand-logo {
      margin: 0;
      position: static;
    }

    .feah-feature-img {
      display: none;
    }

    .feah-feature-item-content {
      padding: 0px 15px;
    }

    .feah-feature-item {
      width: 260px;
    }

    .feah-section-title h2 {
      font-size: 30px;
    }

    .feah-section-title .title-serial {
      font-size: 45px;
    }

    .feah-section-title {
      padding-bottom: 30px;
    }

    .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a {
      width: 85px;
    }

    .feah-footer-content .feah-footer-widget .aw-btn-wrap .aw-line {
      display: none;
    }

    .feah-header-section.feah-header-style-two .main-navigation li {
      margin: 0px 15px;
    }

    .feah-testimonial-img-2 {
      margin-right: 40px;
    }

    .feah-service-section-2:before {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .feah-header-section .main-navigation li {
      margin: 0px 15px;
    }

    .feah-feature-item {
      margin-bottom: 30px;
    }

    .feah-about-scroller-wrapper {
      width: 470px;
    }

    .feah-contact-img {
      top: -140px;
      margin-bottom: -140px;
    }

    .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a {
      width: 75px;
    }

    .feah-header-section.feah-header-style-two .header-cta-number {
      display: none !important;
    }

    .feah-about-counter h3 {
      font-size: 120px;
    }

    .feah-about-counter .counter-text {
      margin-top: 15px;
    }

    .feah-service-img-2 {
      display: none;
    }

    .feah-service-text-wrapper {
      padding-top: 0;
    }

    .feah-work-process-system {
      position: static;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    .feah-work-process-section .feah-wp-exp-text2,
    .feah-work-process-section .feah-wp-exp-text1 {
      display: none;
    }

    .ar-team_left_arrow,
    .ar-team_right_arrow {
      position: static;
      margin: 0px 5px;
      display: inline-block;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    .feah-team-content .carousel_nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 30px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .feah-slider-section-2 .feah-banner-img2,
    .feah-slider-section-2 .feah-banner-img1,
    .feah-slider-section-2 .feah-banner-img3,
    .feah-slider-section-2 .feah-banner-img4,
    .feah-slider-section-2 .feah-banner-img5,
    .feah-slider-section-2 .feah-banner-img6,
    .feah-slider-section-2 .feah-banner-img7 {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .w-750px {
      width: 100% !important;
  }
    .feah-side-bar-menu-wrapper {
      display: none !important;
      visibility: hidden;
      height: 0;
    }
    .arck-service-details-category.ul-li-block ul {
      padding: 0;
  }

    .feah-header-section .header-main-menu-wrapper {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: end !important;
    }

    .feah-slider-content:before {
      display: none;
    }

    .feah-slider-counter .feah-slider-counter-text {
      margin: 0 auto;
    }

    .feah-slider-counter .ar-slider-shape-img {
      display: none;
    }

    .feah-slider-text {
      text-align: center;
    }

    .feah-video-play-btn {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .feah-slider-counter .feah-slider-counter-text h2 {
      font-size: 130px;
    }

    .feah-slider-text {
      padding-left: 0;
    }

    .feah-faq-img-wrap {
      margin: 0 auto;
      max-width: 470px;
      margin-top: 30px;
    }

    .feah-service-text-area {
      margin: 0 auto;
      max-width: 570px;
      margin-bottom: 40px;
    }

    .feah-about-section:before {
      display: none;
    }

    .feah-about-text-content {
      margin-bottom: 30px;
    }

    .feah-about-experience-scoller {
      margin: 0 auto;
      max-width: 700px;
      margin-top: 40px;
    }

    .feah-contact-img {
      top: 0;
      margin: 0 auto;
      max-width: 450px;
      margin-bottom: 0;
    }

    .feah-contact-form-wrapper {
      padding: 50px;
      margin: 15px 0;
  }
  .feah-contact-form button {
    width: auto;
    padding: 0 12px;
  }

    .feah-testimonial-img-wrap {
      display: none !important;
    }

    .feah-blog-item {
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .feah-footer-content .feah-footer-widget .menu-widget {
      padding-left: 0;
    }

    .feah-footer-content .feah-footer-widget {
      margin-bottom: 30px;
    }

    .feah-footer-content .feah-footer-widget .aw-instagram-wrap li a {
      height: auto;
    }

    .feah-testimonial-section {
      overflow: hidden;
    }

    .feah-header-section .mobile_menu_button {
      top: 50%;
      left: 15px;
      right: auto;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: var(--main-color-2);
    }

    .feah-header-section.feah-header-style-two .feah-main-navigation-wrap {
      display: none;
    }

    .feah-header-section.feah-header-style-two .header-main-menu-wrapper {
      padding: 10px 15px;
    }

    .feah-header-section.feah-header-style-two .brand-logo {
      left: 0;
      top: 50%;
      right: 0;
      margin: 0 auto;
      max-width: 150px;
      position: absolute;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .feah-slider-section-2 {
      padding-top: 65px;
    }

    .feah-about-img-wrapper-2 {
      margin: 0 auto;
      max-width: 370px;
    }

    .feah-about-service-content {
      margin: 0 auto;
      margin-top: 40px;
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: start !important;
    }

    .feah-about-service-area {
      width: 100%;
      margin: 0 auto;
    }

    .feah-about-counter {
      padding-top: 50px;
    }

    .feah-service-section-2 .feah-section-title {
      padding-top: 0;
    }

    .feah-service-section-2 .feah-section-title {
      margin: 0 auto;
      text-align: center;
    }

    .feah-service-content-2 .feah-feature-item:last-child:after {
      display: none;
    }

    .feah-service-content-2 .feah-feature-item,
    .feah-service-content-2 .feah-feature-item .feah-feature-text p,
    .feah-service-top-content-2 .feah-feature-item .feah-feature-text p {
      width: 100%;
      max-width: 100%;
    }

    .feah-service-top-content-2 .feah-feature-item {
      width: 50%;
    }

    .feah-service-top-content-2 .top-service-item {
      margin-left: 0;
    }

    .feah-service-content-2 .feah-feature-item {
      margin-top: 0;
    }

    .feah-service-text-wrapper {
      display: none;
    }

    .feah-service-content-2 {
      padding-bottom: 0px;
    }

    .feah-faq-pointer-wrapper {
      display: none;
    }

    .feah-faq-section-2:before {
      width: 100%;
    }

    .feah-fun-fact-title {
      margin-bottom: 30px;
    }

    .feah-team-item .team-text {
      padding: 10px 15px;
    }

    .feah-work-process-system .feah-work-process-system-content {
      padding: 40px 15px;
    }

    .feah-service-top-content-2 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 800px) {
    .feah-textimonial-text-2 h3 {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 680px) {
    .feah-project-top-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .ar-pro_left_arrow,
    .ar-pro_right_arrow {
      margin-left: 0;
      margin-top: 20px;
      margin-right: 5px;
    }

    .feah-newslatter-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-project-item-content {
      margin: 0 auto;
      max-width: 368px;
    }

    .feah-contact-map-form .feah-contact-map {
      width: 100%;
    }

    .feah-contact-map-form .feah-contact-form-wrapper {
      width: 100%;
    }

    .feah-contact-map-form {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-contact-map-form .feah-contact-form-wrapper {
      padding-left: 15px;
    }

    .feah-testimonial-img-text-2 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-textimonial-text-2 {
      padding-top: 30px;
    }

    .feah-service-top-content-2 .feah-feature-item {
      width: 100%;
      padding-right: 15px;
    }

    .feah-slider-content-2 .feah-slider-text-2 h1 {
      font-size: 30px;
      padding-bottom: 20px;
    }

    .feah-slider-content-2 .feah-slider-text-2 {
      padding: 130px 0px 150px;
    }

    .feah-project-item-3 .feah-project-text {
      padding: 15px 20px;
    }

    .feah-fun-fact-counter {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-fun-fact-item {
      width: 100%;
      margin-bottom: 20px;
    }

    .feah-produce-slider .slick-slide img {
      display: inherit;
    }

    .feah-contact-section-2 .feah-contact-form-wrapper {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .feah-header-section .header-cta-number {
      display: none !important;
    }

    .feah-slider-section {
      padding: 290px 0px 150px;
    }

    .feah-slider-counter .feah-slider-counter-text {
      margin-bottom: 30px;
    }

    .feah-slider-counter .feah-slider-counter-text h2 {
      font-size: 100px;
    }

    .feah-slider-text h1 {
      font-size: 36px;
      line-height: 40px;
    }

    .feah-slider-text .feah-video-play-btn .feah-slider-btn a {
      height: 50px;
      width: 150px;
    }

    .feah-project-item {
      width: 100%;
    }

    .feah-about-experience-scoller {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-about-scroller-wrapper {
      margin-left: 0;
    }

    .feah-about-experience {
      width: 100%;
      margin-bottom: 30px;
    }

    .feah-about-sponsor-scroller {
      padding-left: 0;
    }

    .feah-contact-img .img-shape {
      display: none;
    }

    .header-language-select {
      display: none;
    }

    .feah-section-title h2 {
      font-size: 26px;
      line-height: 32px;
    }

    .feah-faq-section,
    .feah-before-after-section,
    .feah-service-section,
    .feah-about-section,
    .feah-project-section-2 {
      padding: 60px 0px;
    }

    .feah-feature-cta-text {
      margin-top: 10px;
    }

    .feah-service-text-area {
      margin-bottom: 20px;
    }

    .feah-about-section {
      background-color: #f7f7f7;
    }

    .ar-pro_left_arrow,
    .ar-pro_right_arrow {
      margin-top: 0;
    }

    .feah-project-section-2 .feah-section-title .title-serial {
      padding-bottom: 0;
    }

    .feah-newslatter-form {
      width: 100%;
    }

    .feah-newslatter-form .newslatter-form {
      max-width: 100%;
    }

    .feah-testimonial-top-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-testimonial-section .feah-section-title {
      padding-bottom: 20px;
    }

    .feah-testimonial-slider {
      padding-top: 40px;
    }

    .feah-testimonial-section {
      padding-bottom: 60px;
    }

    .feah-social-content .feah-social-item {
      width: 50%;
    }

    .feah-social-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-footer-section {
      padding-top: 60px;
    }

    .feah-footer-content .feah-footer-widget .aw-instagram-wrap {
      margin: 20px 0px;
    }

    .feah-footer-content .feah-footer-widget .award-widget .aw-number {
      font-size: 40px;
    }

    .feah-footer-copyright {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .feah-footer-copyright-social {
      margin-top: 10px;
    }

    .feah-footer-copyright-social a {
      margin-left: 0;
      margin: 0px 5px;
    }

    .feah-header-section .header-main-menu-wrapper {
      padding: 10px 15px;
    }

    .feah-header-section.sticky_on .header-main-menu-wrapper {
      padding: 10px 0px;
    }

    .feah-header-section .header-main-menu-wrapper:before {
      display: none;
    }

    .feah-blog-section {
      margin-bottom: 60px;
    }

    .feah-faq-content-wrapper .accordion-button:not(.collapsed)::after,
    .feah-faq-content-wrapper .accordion-button::after {
      font-size: 12px;
    }

    .feah-header-section.feah-header-style-two .header-cta-btn a {
      width: 100px;
      font-size: 12px;
    }

    .feah-header-section.feah-header-style-two .header-cta-btn a i {
      display: none;
    }

    .feah-about-img-wrapper-2 {
      max-width: 330px;
    }

    .feah-about-section-2 {
      padding: 60px 0px;
    }

    .feah-service-content-2 .feah-feature-item {
      margin-top: 20px;
    }

    .feah-work-process-item:after {
      display: none;
    }

    .feah-work-process-title h3 {
      font-size: 28px;
    }

    .feah-work-process-item .feah-work-process-serial {
      font-size: 30px;
    }

    .feah-project-section-3,
    .feah-faq-section-2,
    .feah-team-content,
    .feah-testimonial-section-2,
    .feah-blog-section-2 {
      padding: 60px 0px;
    }

    .feah-fun-fact-item h3 {
      font-size: 40px;
    }

    .feah-fun-fact-item p {
      font-size: 14px;
    }

    .feah-team-item {
      margin: 0 auto;
    }

    .feah-newslatter-content-area {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .feah-newslatter-text {
      margin-bottom: 30px;
    }

    .feah-service-section-2 {
      overflow: hidden;
    }

    .feah-service-section-2 .feah-section-title {
      padding-bottom: 40px;
    }

    .feah-header-section.feah-header-style-two.sticky_on .header-main-menu-wrapper {
      padding: 10px 15px;
    }

    .feah-testimonial-slider-2 .slick-dots {
      display: none !important;
    }

    .feah-testimonial-2-slider-wrapper .carousel_nav {
      position: static;
      margin-top: 30px;
    }

    .feah-testimonial-2-slider-wrapper .ar-tst_2_left_arrow,
    .feah-testimonial-2-slider-wrapper .ar-tst_2_right_arrow {
      position: static;
    }
  }

  /*Breadrumb Section*/
  /*---------------------------------------------------*/
  /*---------------------------------------------------*/
  .arck-breadcrumb-section-2 {
    background-color: #f5f5f5;
    background-image: url("../img/slider-2/s3.png");

  }

  .arck-breadcrumb-section-2 .arck-breadcrumb-content {
    padding: 185px 0px 50px;
  }

  .arck-breadcrumb-section-2 .arck-breadcrumb-content h1 {
    color: #ffffff;
    font-size: 48px;
    padding-bottom: 18px;
  }

  .arck-breadcrumb-section-2 .arck-breadcrumb-content li {
    color: #ffffff;
    text-transform: capitalize;
  }

  .arck-breadcrumb-section-2 .arck-breadcrumb-content li a {
    color: #ffffff;
  }

  .arck-breadcrumb-section-2 .arck-breadcrumb-content li:before {
    display: none;
  }

  @media screen and (max-width: 1600px) {
    .feah-feature-img_2 .feah-ft-shape {
      display: none;
    }
  }

  @media screen and (max-width: 1300px) {
    .feah-feature-content-item_2 {
      padding-left: 0;
      padding-top: 100px;
    }

    .feah-feature-img_2 {
      top: 0;
      margin-bottom: 0;
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 1024px) {
    .feah-feature-content-item_2 {
      padding-top: 50px;
    }

    .feah-feature-img_2 .award-wining-text {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .feah-feature-img_2 {
      max-width: 355px;
      margin: 50px auto 0px;
    }

    .feah-feature-item_2 {
      margin: 0 auto;
      max-width: 370px;
      text-align: center;
      margin-bottom: 40px;
    }

    .feah-feature-cta-text {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 auto;
      max-width: 370px;
      text-align: center;
    }
  }

  /*---------------------------------------------------- */
  /*Responsive area*/
  /*----------------------------------------------------*/
  @media screen and (max-width: 1500px) {
    .arck-header-section.header-style-one {
      padding: 25px 20px;
    }

    .arck-slider-section-1 {
      padding: 80px 20px 0px 20px;
    }

    .arck-header-section .main-navigation li {
      margin: 0px 20px;
    }

    .arck-header-section.header-style-one .header-cta-social .header-social {
      margin-left: 20px;
    }

    .arck-header-section.header-style-one .header-cta-social .header-social:before {
      left: 0;
    }

    .arck-header-section .dropdown-menu {
      top: 55px;
    }

    .arck-header-section.sticky-on .dropdown-menu {
      top: 52px;
    }

    .arck-main-slider-item .arck-slider-main-text:before {
      left: -45px;
      top: -50px;
    }

    .arck-main-slider-item .arck-slider-main-text:after {
      left: -55px;
      top: -40px;
    }

    .arck-video-cta-wrap .video-cta-item .inner-icon {
      margin-right: 10px;
    }

    .arck-video-cta-wrap .video-cta-item .inner-text h3 {
      font-size: 16px;
    }

    .arck-video-cta-wrap .video-cta-item {
      padding: 20px 15px;
    }

    .arck-video-play-wrap {
      width: 52%;
    }

    .arck-appointment-form-wrap {
      max-width: 530px;
    }
  }

  @media screen and (max-width: 1280px) {

    .arck-main-slider-item .arck-slider-main-text:before,
    .arck-main-slider-item .arck-slider-main-text:after {
      display: none;
    }

    .arck-header-section.header-style-one .header-cta-social .header-cta {
      display: none;
    }

    .arck-header-section.header-style-one .header-cta-social .header-social:before {
      display: none;
    }

    .arck-project-item .inner-text {
      max-width: 300px;
    }

    .arck-main-slider-area-2 .carousel_nav {
      display: none;
    }

    .arck-main-slider-item-2 {
      padding: 230px 0px 110px;
    }
  }

  @media screen and (max-width: 1024px) {
    .arck-project-slider-wrapper {
      padding: 0px 15px;
    }

    .arck-project-item .inner-text .project-title-desc p {
      display: none;
    }

    .arck-project-item .inner-text {
      top: 40px;
      left: 40px;
      max-width: 230px;
    }

    .arck-project-item .view-more-btn {
      bottom: 40px;
    }

    .arck-video-play-wrap {
      width: 48%;
    }

    .arck-appointment-form-wrap {
      max-width: 50%;
    }

    .arck-video-cta-wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .arck-video-cta-wrap .video-cta-item {
      width: 50%;
    }

    .arck-sponsor-section {
      padding: 70px 0px;
    }

    .arck-blog-item-2 .inner-text p,
    .arck-blog-item .inner-text p {
      display: none;
    }

    .arck-blog-item-2 .inner-text {
      padding: 45px 15px 40px;
    }

    .about-signature-img .sign-img {
      right: 0;
    }

    .filtr-container-area .grid-size-25 {
      width: 50%;
    }

    .arck-working-skill-text-wrap {
      padding-top: 0px;
    }

    .arck-skill-progress-bar {
      padding-top: 20px;
    }

    .arck-testimonial-appointment-content .arck-appointment-form-wrap {
      max-width: 100%;
    }

    .arck-testimonial-appointment-content .arck-appointment-form-wrap {
      padding-left: 20px;
    }

    .arck-main-slider-item-2 .arck-slider-main-text {
      max-width: 485px;
    }

    .arck-main-slider-item-2 .arck-slider-main-text h1 {
      font-size: 60px;
    }

    .arck-slider-section-2 .slider-social {
      left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .arck-main-navigation-wrap {
      display: none;
    }

    .arck-slider-area .slick-dots {
      right: 30px;
    }

    .arck-header-section.header-style-one .header-cta-social {
      display: none !important;
    }

    .mobile_menu_button {
      display: block;
    }

    .no-padding {
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
    }

    .arck-about-text-wrapper {
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .arck-about-img-wrapper,
    .arck-testimonial-img-wrap {
      max-width: 570px;
      margin: 0 auto;
    }

    .arck-work-process-item {
      margin-bottom: 60px;
    }

    .arck-work-process-section {
      padding-bottom: 90px;
    }

    .arck-counter-inner-item {
      margin-bottom: 40px;
    }

    .arck-testimonial-text-wrap {
      padding-left: 0;
      margin: 0 auto;
      max-width: 570px;
    }

    .arck-blog-item,
    .arck-blog-item-2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 auto;
      max-width: 770px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .arck-blog-item-wrapper .row {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .arck-blog-item-2 .inner-text {
      padding: 45px 35px 40px;
    }

    .arck-footer-widget {
      margin-bottom: 40px;
    }

    .arck-footer-widget .widget-title {
      padding-bottom: 20px;
    }

    .arck-footer-widget-wrapper {
      padding-bottom: 50px;
    }

    .arck-slider-section-2 .arck-slider-img-2 {
      display: none;
    }

    .arck-about-img-wrap-2,
    .arck-working-skill-text-wrap,
    .arck-contact-page-cta {
      margin: 0 auto;
      max-width: 570px;
      margin-bottom: 30px;
    }

    .arck-about-text-wrap-2,
    .arck-working-skill-img,
    .arck-testimonial-appointment-content .arck-appointment-form-wrap,
    .arck-faq-video-play-wrap,
    .arck-appointment-form-wrap {
      margin: 0 auto;
      padding-left: 0;
      max-width: 570px;
    }

    .arck-pricing-item {
      margin-bottom: 30px;
    }

    .arck-testimonial-appointment:before {
      display: none;
    }

    .arck-testimonial-appointment-content .arck-testimonial-text-wrap {
      padding-right: 0;
      padding-bottom: 60px;
    }

    .about-page-about-content .arck-about-img-wrapper {
      margin-bottom: 60px;
    }

    .arck-faq-content {
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .shop-details-text-wrap {
      padding-left: 0;
    }

    .arck-service-details-category {
      margin-bottom: 40px;
    }

    .arck-blog-sidebar {
      margin-top: 0px;
    }

    .arck-contact-page-form {
      padding-left: 0;
    }

    .arck-project-details-img-item {
      margin-bottom: 30px;
    }

    .arck-project-details-text {
      margin-top: 30px;
    }

    .mobile_menu {
      display: block;
    }

    .feah-header-section .header-main-menu-wrapper:before {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .arck-main-slider-item .arck-slider-main-text h1 {
      font-size: 60px;
    }

    .arck-main-slider-item .arck-slider-main-text {
      max-width: 530px;
    }

    .arck-section-title h2,
    .arck-section-title-2 h2 {
      font-size: 40px;
    }

    .arck-project-filter-btn li,
    .arck-project-filter-btn button,
    .arck-project-filter-btn li,
    .arck-project-filter-btn button {
      margin-left: 15px;
    }

    .arck-project-top-content {
      padding-bottom: 40px;
    }

    .arck-appointment-form-wrap {
      max-width: 100%;
    }

    .arck-video-play-wrap {
      width: 100%;
      height: 500px;
      margin-top: 50px;
      position: relative !important;
    }

    .arck-video-cta-wrap {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }

    .arck-appointment-video-section {
      padding-bottom: 0;
    }

    .arck-blog-item,
    .arck-blog-item-2 {
      margin: 0 auto;
      display: block;
      max-width: 370px;
      margin-bottom: 40px;
    }

    .filtr-container-area .grid-sizer,
    .filtr-container-area .grid-size-50,
    .filtr-container-area .grid-size-25 {
      width: 100%;
    }

    .arck-blog-details-main-content .arck-blog-share-tag {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 680px) {
    .arck-main-slider-item .arck-slider-main-text .line-shape {
      display: none;
    }

    .arck-video-cta-wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      position: relative !important;
    }

    .arck-video-cta-wrap .video-cta-item {
      width: 100%;
    }

    .arck-video-play-wrap .arck-video-img-wrap {
      display: block;
    }

    .arck-video-play-wrap {
      height: auto;
    }

    .arck-video-play-btn {
      top: 25%;
    }

    .arck-cta-content h2 {
      font-size: 30px;
    }

    .arck-cta-content .arck-cta-button-group {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .arck-cta-number {
      margin-top: 30px;
    }

    .arck-cta-content .arck-cta-number i {
      width: 50px;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
    }

    .arck-cta-content .arck-cta-number a {
      font-size: 20px;
    }

    .filtr-container-area .grid_2-size-40,
    .filtr-container-area .grid_2-size-60,
    .filtr-container-area .grid_2-sizer {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .arck-main-slider-item .arck-slider-main-text h1 {
      font-size: 40px;
    }

    .arck-main-slider-item .arck-slider-main-text {
      max-width: 310px;
    }

    .arck-slider-section-1 {
      padding: 80px 0px 0px;
    }

    .arck-slider-area .slick-dots {
      right: 15px;
    }

    .arck-main-slider-item .arck-slider-main-text h1 {
    
      
    }
     .feah-slider-text .slider-slug{
    margin-bottom: 0;
  }
  .feah-counter-wrapper {
    margin-top: 35px;
}
.feah-counter-item.headline-2.pera-content {
  width: 33.3%;
  text-align: center;
}

    .arck-slider-section-1:before,
    .arck-slider-section-1:after {
      display: none;
    }

    .arck-main-slider-item {
      padding: 150px 0px 160px;
    }

    .arck-appointment-form-wrap form button,
    .arck-btn a {
      height: 50px;
      width: 150px;
    }

    .arck-service-section,
    .arck-about-section {
      padding: 60px 0px;
    }

    .arck-section-title h2,
    .arck-section-title-2 h2 {
      font-size: 30px;
      padding-bottom: 20px;
    }

    .arck-about-text-wrapper .arck-year-experience h3 {
      font-size: 60px;
    }

    .arck-project-top-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-bottom: 20px;
    }

    .arck-project-top-content .arck-section-title {
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
    }

    .arck-project-section {
      padding: 60px 0px;
    }

    .arck-work-process-content {
      padding-top: 70px;
    }

    .arck-work-process-section {
      padding-bottom: 60px;
    }

    .arck-counter-section {
      padding: 60px 0px 40px;
    }

    .arck-appointment-video-section {
      padding-top: 80px;
    }

    .arck-appointment-form-wrap .arck-section-title {
      padding-bottom: 15px;
    }

    .arck-team-section {
      padding: 60px 0px 30px;
    }

    .arck-teestimonial-item .inner-text {
      font-size: 18px;
      margin-bottom: 45px;
    }

    .arck-blog-section {
      padding: 60px 0px 35px;
    }

    .arck-sponsor-section {
      padding: 40px 0px;
    }

    .arck-footer-widget-wrapper {
      padding-bottom: 20px;
    }

    .arck-main-slider-item-2 .arck-slider-main-text h1 {
      font-size: 40px;
      padding-bottom: 10px;
    }

    .arck-main-slider-item-2 .arck-slider-main-text p {
      padding-bottom: 20px;
    }

    .arck-btn-2 a {
      height: 50px;
      width: 150px;
    }

    .arck-about-section-2,
    .arck-before-after-section,
    .arck-working-skill-section,
    .arck-pricing-section,
    .arck-testimonial-appointment {
      padding: 60px 0px;
    }

    .arck-before-after-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .arck-before-after-content {
      padding-top: 20px;
    }

    .arck-project-section-2 {
      padding-top: 40px;
    }

    .arck-project-filter-content {
      padding: 0px 15px;
    }

    .arck-project-item-2 .inner-text {
      bottom: 25px;
    }

    .arck-project-item-2:hover .inner-text {
      left: 25px;
    }

    .arck-breadcrumb-content {
      padding: 175px 0px 140px;
    }

    .arck-breadcrumb-section .acrk-img-shape2,
    .arck-breadcrumb-section .acrk-img-shape1 {
      display: none;
    }

    .arck-breadcrumb-content h1 {
      font-size: 28px !important;
      padding-bottom: 15px;
    }
    .arck-breadcrumb-section-2 .arck-breadcrumb-content {
      padding: 180px 0px 30px !important;
  }

    .about-page-about-section {
      padding: 60px 0px;
    }

    .arck-cta-content,
    .arck-faq-video-section {
      padding: 60px 0px;
    }

    .arck-faq-video-play-wrap .arck-video-play-btn {
      top: 50%;
    }

    .inner-page-padding {
      padding: 60px 0px;
    }

    .shop-details-text-wrap .arck-code-category li {
      margin-right: 10px;
    }

    .shop-details-text-wrap .quantity-btn-area .add-cart-btn a {
      width: 140px;
    }

    .arck-service-details-text-area h3 {
      font-size: 30px;
      padding-bottom: 15px;
    }

    .arck-blog-details-main-content .arck-blog-details-text-wrap {
      padding: 50px 20px 20px;
    }

    .arck-blog-details-main-content .arck-blog-share-tag .blog-hash-tag a {
      margin-left: 0;
    }

    .arck-blog-details-main-content .arck-blog-share-tag .blog-hash-tag {
      margin-top: 15px;
    }

    .contact-cta-inner-img {
      display: none;
    }

    .arck-contact-page-cta .contact-cta-inner-text {
      position: static !important;
    }

    .arck-faq-feed-content {
      padding-top: 30px;
    }

    .arck-faq-feed-section {
      padding: 60px 0px;
    }

    .arck-testimonial-feed-section {
      padding: 60px 0px 20px;
    }
  }

  .bg:after {
    background-image: url(https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img9.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

}
.slidershadow2 {
  float: left;
  width: 100%;
  height: 130px;
  background: url(../img/shape/btm.png) no-repeat center top;
  position: absolute;
  z-index: 1;
  opacity: 1;
  margin-top: -110px;
  background-size: cover;

  }

  /*---------------------------------------------------- */
  .mt-0 {
    margin-top: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .feah-faq-content-wrapper {
    width: 100%;
  }

  .accordion-button::after {
    margin-right: inherit;
  }

  .feah-footer-copyright-text {
    color: #fff;
  }

  .header-language-select-social.d-flex {
    align-items: center;
  }
}

[dir="rtl"] .end-0 {
  right: auto !important;
}
.p-dropdown-panel.p-component.ng-star-inserted {
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 20px 0 10px;
}